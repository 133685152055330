import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {IMenuItem} from "modules/types";
import {media} from "assets/css/media";
import hamburgerIcon from "assets/img/icons/menu_hamburger.svg";
import closeIcon from "assets/img/icons/close_Icon.svg";
import {partial} from "lodash";
import {toggleFixedBody} from "modules/utils/helpers";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {isAuthedSelector} from "modules/selectors";
import {MobileItem} from "components/Navigation/MobileItem";
import {useLocation} from "react-router-dom";
import {usePrevious} from "modules/hooks";
import {setGlobalLoaderStateAction} from "modules/actions/global";
import {GameSettingsUtils} from "modules/utils/secret";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media (min-width: ${media.desktop}) {
		display: none;
	}
`;
const MenuHamburger = styled.img`
	width: 30px;
	height: 30px;
	margin-right: 10px;
	cursor: pointer;
`;
const CloseBlock = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 62px;
	height: 62px;
	background: #12167f;
`;
const CloseBlockIcon = styled.img`
	width: 17px;
	height: 17px;
	cursor: pointer;
`;
const Menu = styled.div.withConfig({
	shouldForwardProp: (prop) => !["isOpen"].includes(prop),
})<{isOpen: boolean}>`
	background: #12156f;
	position: absolute;
	height: ${(props) => (props.isOpen ? "calc(100vh - 62px)" : 0)};
	width: 108%;
	left: -13px;
	top: 100%;
	display: flex;
	flex-direction: column;
	transition: height 0.2s linear;
	z-index: 5;
	overflow: hidden;
`;

const LogoutButton = styled.div`
	height: 70px;
	background: #151515;
	width: 100%;
	margin-top: auto;
	color: #ffffff;
	font-size: 16px;
	font-family: ${theme.mediumFont};
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	img {
		position: absolute;
		left: 20px;
	}
`;

interface IProps {
	menuItems: IMenuItem[];
}

export const MobileMenu: React.FC<IProps> = ({menuItems}) => {
	const dispatch = useDispatch();
	const [isShowMenu, setIsShowMenu] = useState(false);
	const isAuthed = useSelector(isAuthedSelector);
	const location = useLocation();
	const prevLocationKey = usePrevious(location.key);
	const isEnabledRoutes = GameSettingsUtils.IS_ENABLED_SESSION;
	toggleFixedBody(isShowMenu);
	useEffect(() => {
		if (location.key !== prevLocationKey) {
			setIsShowMenu(false);
		}
	}, [location, prevLocationKey]);

	const logOutHandler = () => {
		dispatch(setGlobalLoaderStateAction(true));
		window.gigyaInstance.logout();
	};
	return isEnabledRoutes ? (
		<Wrapper>
			{isShowMenu ? (
				<CloseBlock>
					<CloseBlockIcon src={closeIcon} alt={"Close Icon"} onClick={partial(setIsShowMenu, !isShowMenu)} />
				</CloseBlock>
			) : (
				<MenuHamburger
					src={hamburgerIcon}
					alt={"Hamburder Icon"}
					onClick={partial(setIsShowMenu, !isShowMenu)}
				/>
			)}
			<Menu isOpen={isShowMenu}>
				{menuItems.map((item) => (
					<MobileItem key={item.label} item={item} />
				))}
				{isAuthed ? (
					<LogoutButton onClick={logOutHandler}>
						{window.getTranslations("global_button_bracket_bracket_button_logout", "LOG OUT")}
					</LogoutButton>
				) : null}
			</Menu>
		</Wrapper>
	) : null;
};
