import styled, {css} from "styled-components";
import theme from "assets/css/theme";
import mxBackground from "assets/img/mx/background.jpg";
import {NavLink} from "react-router-dom";

export const FooterWrapper = styled.div.withConfig({
	shouldForwardProp: (prop) => !["isMX"].includes(prop),
})<{isMX?: boolean}>`
	background-color: ${theme.footer_bg};
	color: ${theme.menu_color};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px 0;

	${(props) =>
		props.isMX &&
		css`
			background: url("${mxBackground}");
		`}
`;

export const FooterMenuWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const TermStyles = css`
	position: relative;
	font-size: 14px;
	color: #555555;
	padding-bottom: 5px;
	line-height: 22px;
	&:after {
		content: "";
		position: absolute;
		width: 100%;
		border-bottom: 1px solid currentColor;
		left: 0;
		bottom: 1px;
	}

	&:hover {
		color: #fff;
	}
`;
export const TermLink = styled(NavLink)`
	${TermStyles};
`;

export const ExtrnalLink = styled.a`
	${TermStyles};
`;

export const TermButton = styled.button`
	background: transparent !important;
	position: relative !important;
	font-size: 14px !important;
	color: #555555 !important;
	padding: 0 0 5px 0 !important;

	line-height: 22px !important;
	border: none !important;
	outline: none !important;
	font-weight: inherit !important;
	font-family: inherit !important;

	&:hover {
		color: #fff !important;
	}

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		border-bottom: 1px solid currentColor;
		left: 0;
		bottom: 1px;
	}
	${TermStyles};
`;

export const Copywriting = styled.div`
	font-size: 12px;
	line-height: 22px;
	max-width: 900px;
	text-align: center;
	color: ${theme.footer_text_color};
`;

export const FooterImgWrapDiv = styled.div`
	display: flex;
	flex-direction: row;
`;

export const FooterImgDiv = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	margin: 0 20px;
`;

export const FooterImg = styled.img`
	max-width: 50px;
`;

export const FooterTermsDiv = styled.div`
	display: flex;
	gap: 12px;
	margin-bottom: 20px;
	text-align: center;
	@media (max-width: 768px) {
		flex-flow: column;
		gap: 8px;
	}
`;
