import {SagaIterator} from "redux-saga";
import {call, put} from "redux-saga/effects";
import {IEmail, ISagaAction} from "modules/types";
import {Api, ApiError} from "modules/utils/Api";
import * as actions from "modules/actions";

const GAME_NAME = import.meta.env.VITE_NAME || "";

export interface IContactUSSagaAction extends IEmail {
	readonly name: string;
	readonly category: string;
	readonly question: string;
	readonly is_success: boolean;
}

export const postContactUsSaga = function* (action: ISagaAction<IContactUSSagaAction>): SagaIterator {
	try {
		const {errors} = yield call(Api.Contact.save, action.payload);

		ApiError.CHECK(errors);

		if (GAME_NAME === "nfl-gb" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(actions.saveContactUsSuccess());
	} catch (e) {
		yield put(actions.saveContactUsFailed());
	}
};
