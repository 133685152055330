import {differenceBy, first, flatten, flow, get, identity, isEmpty, last, partial, toPairs} from "lodash";
import {
	fetchChecksumsJSONSuccess,
	fetchChecksumsJSONFailed,
	unsubscribeFromLiveScores,
} from "modules/actions/checksums";
import {checksumsSelector} from "modules/selectors";
import {ISagaAction, ISubscribeToChecksums, LiveScoreSection} from "modules/types";
import {Api, ApiError} from "modules/utils/Api";
import {Saga, SagaIterator} from "redux-saga";
import {all, call, race, select, take, delay, put} from "redux-saga/effects";
import {fetchRoundsSaga, fetchSquadsSaga} from "modules/sagas/jsons";
import {fetchPredictionsSaga} from "modules/sagas/predictions";

const ONE_MIN = 55;
const THOUSAND = 1000;

type TMap = {
	[key in LiveScoreSection]: Saga[];
};

/**
 * Keys for listener
 */
type CheckSumsKeys = "rounds";

type TMapChecksums = {
	[key in CheckSumsKeys]: TMap;
};

/**
 * If we need add some additional actions.
 * ladder - just a sections of APP
 *
 */
const mapChecksumsToSaga: TMapChecksums = {
	rounds: {
		brackets: [fetchRoundsSaga, fetchSquadsSaga, fetchPredictionsSaga],
	},
};

const getChecksumApi = (section: LiveScoreSection) => {
	return {
		brackets: Api.JSON.checksums,
	}[section];
};

export const fetchChecksumsJSONSaga = function* (section: LiveScoreSection) {
	const checksumApi = getChecksumApi(section);

	try {
		const response = yield call(checksumApi);

		ApiError.CHECK(response);
		yield put(fetchChecksumsJSONSuccess(response));
	} catch (e) {
		yield put(fetchChecksumsJSONFailed(e as Error));
	}
};

export const ChecksumsToSaga = function* (action: ISagaAction<ISubscribeToChecksums>): SagaIterator {
	const {section} = action.payload;
	const checksums = yield select(checksumsSelector);

	if (isEmpty(checksums)) {
		yield call(fetchChecksumsJSONSaga, section);
	}
	/**
	 * I think here we should add a check on lockout
	 * if current is state is locked  then we request checksums per minute otherwise per hour
	 */

	const TIME = THOUSAND * ONE_MIN;

	const {stopped} = yield race({
		wait: delay(TIME),
		stopped: take(unsubscribeFromLiveScores),
	});

	if (!stopped) {
		const [old_checksums] = yield all([select(checksumsSelector), call(fetchChecksumsJSONSaga, section)]);

		const new_checksums = yield select(checksumsSelector);

		const requestsForChanges = flatten(
			differenceBy(toPairs(old_checksums), toPairs(new_checksums), (arr: any) => {
				return last<string>(arr ? arr : [""]);
			})
				.map(flow([first, partial(get, mapChecksumsToSaga), partial(get, partial.placeholder, section)]))
				.filter(identity)
		);

		if (!isEmpty(requestsForChanges)) {
			yield all(
				requestsForChanges.map((request: any) => {
					return call(request);
				})
			);
		}

		yield call(ChecksumsToSaga, action);
	}
};
