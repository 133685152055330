import {errorsGlobalError} from "modules/actions";
import {fetchTranslationsSuccessAction} from "modules/actions/translations";
import {CONFIG_COUNTRY} from "modules/constants";
import {Api} from "modules/utils/Api";
import {ApiError} from "modules/utils/Api/ApiError";
import {call, put, select} from "redux-saga/effects";
import {selectedLang} from "modules/selectors";

// const checkFrLanguage = (browserLanguage: string) => browserLanguage === "fr-ca" || browserLanguage === "fr";

// eslint-disable-next-line complexity
export const fetchTranslationsSaga = function* () {
	try {
		const country = String(CONFIG_COUNTRY);
		window.LANGUAGE = import.meta.env.VITE_DEFAULT_LANG || "en";

		// Old code for change language for Canada:

		// if (country === "ca") {
		// 	const browserLanguage = String(window.navigator.language || lang).toLowerCase();
		// 	window.LANGUAGE = checkFrLanguage(browserLanguage) ? "fr-ca" : "en";
		// } else {
		// 	window.LANGUAGE = lang;
		// }

		if (country === "fr") {
			const response = yield call(Api.Global.translations, "fr-fr");
			ApiError.CHECK(response);
			yield put(fetchTranslationsSuccessAction(response));
		} else if (country === "us") {
			const langSelected = yield select(selectedLang);
			const response = yield call(Api.Global.translations, langSelected);
			ApiError.CHECK(response);
			yield put(fetchTranslationsSuccessAction(response));
		} else {
			const response = yield call(Api.Global.translations, window.LANGUAGE);
			ApiError.CHECK(response);
			yield put(fetchTranslationsSuccessAction(response));
		}
	} catch (e: unknown) {
		console.log(e);
		yield put(errorsGlobalError(e));
	}
};
