import React from "react";
import {getBetMatches} from "modules/selectors/brackets";
import styled, {css} from "styled-components";
import {useSelector} from "react-redux";
import {getSquadByIDSelector} from "modules/selectors/squads";
import {identity, eq, get} from "lodash";
import {Odds} from "components/Odds";
import {SQUAD_LOGO_URL} from "modules/constants";
import {useMediaQuery} from "modules/hooks";
import {Exist} from "components/Exist";

const Wrapper = styled.div`
	position: relative;
	display: flex;
	background: #fff;
`;

const MatchLabel = styled.div`
	width: 18px;
	height: 18px;
	border-radius: 100%;
	background: #fff;
	color: #ccc;
	font-size: 10px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "AllProDisaplayB-Medium", sans-serif;
`;

interface IPartProps {
	isSelected: boolean;
}

const getSelectedTeamStyles = ({isSelected}: IPartProps) =>
	isSelected
		? css`
				background: #f2faff;
				border-color: #fff;
			`
		: "";

const TeamName = styled.div`
	flex: 1;
	font-size: 12px;
	color: #333;
	margin: 0 10px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const Right = styled.div<IPartProps>`
	min-width: 50%;
	max-width: 50%;
	width: 50%;
	padding: 10px 10px 10px 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #eee;
	flex-wrap: nowrap;
	${getSelectedTeamStyles};
`;

const Left = styled(Right)`
	flex-direction: row-reverse;
	padding: 10px 20px 10px 10px;

	${TeamName} {
		text-align: right;
	}
`;

const SquadLogo = styled.img`
	width: 20px;
	height: auto;
`;

const oddsSource = import.meta.env.VITE_ODDS_SOURCE || "";

interface ICalculatorItemProps {
	match: ReturnType<typeof getBetMatches>[number];
}

export const BettingItem: React.FC<ICalculatorItemProps> = ({match}) => {
	const {home_squad_id, home_squad_odds, away_squad_id, away_squad_odds, prediction} = match;

	const isMobile = useMediaQuery("(max-width: 800px)");
	const getSquadByID = useSelector(getSquadByIDSelector);
	const homeSquad = getSquadByID(home_squad_id)!;
	const awaySquad = getSquadByID(away_squad_id)!;
	const hasAllData = [homeSquad, awaySquad].every(identity);

	if (!hasAllData) {
		return null;
	}

	const selectedSquadID = prediction?.squad_id;
	const isHomeSquadSelected = eq(selectedSquadID, home_squad_id);
	const isAwaySquadSelected = eq(selectedSquadID, away_squad_id);

	return (
		<Wrapper>
			<Left isSelected={isAwaySquadSelected}>
				<SquadLogo src={`${SQUAD_LOGO_URL}/${away_squad_id}.png`} alt={awaySquad.full_name} />
				<TeamName>
					<Exist when={!isMobile}>{awaySquad.full_name}</Exist>
					<Exist when={isMobile}>{awaySquad.short_name}</Exist>
				</TeamName>
				<Odds
					isSelected={isAwaySquadSelected}
					oddsValue={get(away_squad_odds, `${oddsSource}.value`, 0)}
					oddsID={get(away_squad_odds, `${oddsSource}.id`, 0)}
				/>
			</Left>
			<MatchLabel>AT</MatchLabel>
			<Right isSelected={isHomeSquadSelected}>
				<SquadLogo src={`${SQUAD_LOGO_URL}/${home_squad_id}.png`} alt={homeSquad.full_name} />
				<TeamName>
					<Exist when={!isMobile}>{homeSquad.full_name}</Exist>
					<Exist when={isMobile}>{homeSquad.short_name}</Exist>
				</TeamName>
				<Odds
					isSelected={isHomeSquadSelected}
					oddsValue={get(home_squad_odds, `${oddsSource}.value`, 0)}
					oddsID={get(home_squad_odds, `${oddsSource}.id`, 0)}
				/>
			</Right>
		</Wrapper>
	);
};
