import {IShare} from "modules/types";
import {createConnextraScriptTag, isMobile} from "modules/utils";
import {ShareType} from "modules/constants/enums";

const FB_SHARE_URL = import.meta.env.VITE_FB_SHARE_URL || "";
const protocol = window.location.protocol;

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: any) => btoa(JSON.stringify(object));
const getLinkForShare = (object: any, type: string) => `${protocol}${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;

export const share = (data: IShare) => {
	createConnextraScriptTag(
		`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_shareclick_${
			data.sc === ShareType.FB ? "facebook" : "twitter"
		}?AccountID=${data?.user_id || 0}`
	);
	if (data.sc === "tw") {
		shareTwitter(data);
	}

	if (data.sc === "fb") {
		shareFB(data);
	}
};

export const getFileToShareImg = (user_id: number) => {
	const baseApiURL = import.meta.env.VITE_FILE_SHARE_URL || "";
	const lang = import.meta.env.VITE_SYNDICATE_COUNTRY || "GB";

	const params = {
		id: user_id,
		country: lang.toLowerCase(),
		or: isMobile() ? "mobile" : "desktop",
		t: getTimestamp(),
	};

	return `${baseApiURL}share/bracket_prediction_dynamic/${getEncodedJson(params)}`;
};

export const shareNavigator = async (data: IShare) => {
	try {
		const {league_id, section, round_id, user_id} = data;
		const object: any = {
			t: getTimestamp(),
			ln: window.LANGUAGE,
			cn: import.meta.env.VITE_SYNDICATE_COUNTRY || "",
		};

		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_shareclick_${
				data.sc === ShareType.FB ? "facebook" : "twitter"
			}?AccountID=${String(user_id)}`
		);

		if (league_id) {
			object.id = league_id;
		}

		if (round_id) {
			object.round = round_id;
		}

		if (user_id) {
			object.user_id = user_id;
		}

		const shareData = {
			url: getLinkForShare(object, section),
			text: "",
		};

		await navigator.share(shareData);
	} catch (err: unknown) {
		console.log(err);
	}
};

export const shareFB = (data: IShare) => {
	const {sc, league_id, section, round_id, user_id} = data;
	const FB_API = window.FB;
	const object: any = {
		sc,
		t: getTimestamp(),
		ln: window.LANGUAGE,
		cn: import.meta.env.VITE_SYNDICATE_COUNTRY || "",
	};

	if (league_id) {
		object.id = league_id;
	}

	if (round_id) {
		object.round = round_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	FB_API.ui({
		method: "share",
		display: "popup",
		href: getLinkForShare(object, section),
	});
};

export const shareTwitter = (data: IShare) => {
	const {sc, league_id, section, round_id, user_id} = data;
	const object: any = {
		sc,
		t: getTimestamp(),
		ln: window.LANGUAGE,
		cn: import.meta.env.VITE_SYNDICATE_COUNTRY || "",
	};

	if (league_id) {
		object.id = league_id;
	}

	if (round_id) {
		object.round = round_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	if (league_id) {
		object.league_id = league_id;
	}
	const href = getLinkForShare(object, section);
	window.open(
		"https://twitter.com/intent/tweet?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(data.message),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};
