// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {lazy, Suspense, Fragment} from "react";
import {createRoot} from "react-dom/client";
import {Route, BrowserRouter as Router, Routes, Outlet} from "react-router-dom";
import NotFound from "pages/NotFound";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {PagePreloader} from "components/Preloader";
import {Provider} from "react-redux";
import {store} from "modules/store";
import {GigyaGate} from "components/GigyaGate";
import {NotAuthOnlyRoute, PrivateRoute} from "components/Route";
import {Translations} from "components/Translations";
import {GlobalPreloader} from "components/GlobalPreloader";
import {ComingSoonModal, CountriesNavigate, HowToPlayModal, VideoPlayerModal} from "components";
import {GlobalErrors} from "components/GlobalErrors";
import {Login} from "pages/Login";
import {IS_UAT} from "modules/constants";
import {GameSettingsUtils} from "modules/utils/secret";
import {RegisterModal} from "components/RegisterModal";
import {ConfirmSaveModal} from "components/ConfirmSaveModal";
import {RecoverModal} from "components/RecoverModal";

type Factory<T> = () => Promise<{
	default: React.ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

const projectName = import.meta.env.VITE_PROJECT_NAME || "defaultProject";

const ProjectIndex = lazy(() => import(`./apps/${projectName}/index.tsx`));
const Backdoor = lazy(retryFailLoad(() => import("pages/Backdoor")));
const Landing = lazy(retryFailLoad(() => import("pages/Landing")));
const Brackets = lazy(retryFailLoad(() => import("pages/Brackets")));
const FanPredictions = lazy(retryFailLoad(() => import("pages/FanPredictions")));
const SharedBrackets = lazy(retryFailLoad(() => import("pages/SharedBrackets")));

const Leagues = lazy(retryFailLoad(() => import("pages/Leagues")));
const Ladder = lazy(retryFailLoad(() => import("pages/league/Ladder")));
const About = lazy(retryFailLoad(() => import("pages/league/About")));
const CreateLeague = lazy(retryFailLoad(() => import("pages/Leagues/CreateLeague")));
const JoinLeague = lazy(retryFailLoad(() => import("pages/Leagues/JoinLeague")));

const Prizes = lazy(retryFailLoad(() => import("pages/Prizes")));

const Help = lazy(retryFailLoad(() => import("./pages/Help")));
const GameGuidelines = lazy(retryFailLoad(() => import("./pages/Help/GameGuidelines")));
const ContactUs = lazy(retryFailLoad(() => import("./pages/Help/ContactUs")));
const FAQs = lazy(retryFailLoad(() => import("./pages/Help/FAQs")));
const GameRules = lazy(retryFailLoad(() => import("./pages/Help/GameRules")));
const PrizesPool = lazy(retryFailLoad(() => import("./pages/Help/PrizesPool")));
const Terms = lazy(retryFailLoad(() => import("./pages/Help/Terms")));
// const PrivacyPolicy = lazy(() => import("./pages/Help/PrivacyPolicy"));

const YourAccount = lazy(retryFailLoad(() => import("./pages/YourAccount")));
const Communications = lazy(retryFailLoad(() => import("./pages/yourAccount/Communications")));
const NFLAccount = lazy(retryFailLoad(() => import("./pages/yourAccount/NFLAccount")));
const DeactivateAccount = lazy(retryFailLoad(() => import("./pages/yourAccount/DeactivateAccount")));

let errorCount = 0;
const MAX_ERRORS = 100;
const IGNORE_ERRORS = [
	"Authentication Required",
	"Load failed",
	"UET is not defined",
	"Loading chunk",
	"AbortError: Share canceled",
	"Failed to fetch",
	"Network Error",
	"The operation is insecure.",
	"Minified Redux error",
	"Unexpected end of script",
	"Abort due to cancellation of share",
	"The network connection was lost.",
	"Maximum call stack size exceeded",
	"Possible side-effect in debug-evaluate",
	"UnhandledRejection",
	"NotFoundError",
	"undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
	"UnhandledRejection: Non-Error promise rejection captured with keys: message, status",
	"Non-Error promise rejection captured with keys: message, status",
	"Cannot read properties of undefined (reading 'domInteractive')",
	"Importing a module script failed.",
	"window.SafeDKWebAppInterface.interceptXmlHttpRequest is not a function",
	"Event `CustomEvent` (type=unhandledrejection) captured as promise rejection",
	"Cannot read properties of undefined (reading 'aa')",
	"Java exception was raised during method invocation",
	"Unable to preload CSS",
	"Error: Java object is gone",
	"Cannot parse given Error object",
];

Sentry.init({
	dsn: "https://6455030823e647d7876a21ee6f65e5ae@o151969.ingest.sentry.io/5521493",
	environment: import.meta.env.VITE_SENTRY_ENV || "development",
	sampleRate: 0.05,
	ignoreErrors: IGNORE_ERRORS,
	release: "0.0.3",
	allowUrls: [
		".fanhubmedia.com",
		"www.superbowlchallenge.com.au",
		"superbowlchallenge.com.br",
		"superbowlchallenge.nfl.com",
		"superbowlchallenge.sport.de",
		"superbowlchallenge.co.uk",
		"superbowlchallenge.canada.nfl.com",
		"superbowlchallenge.france.nfl.com",
		"*.nfl.com",
	],
	integrations: [
		// eslint-disable-next-line @typescript-eslint/unbound-method
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	beforeSend: (event: Sentry.Event) => {
		errorCount++;
		if (errorCount > MAX_ERRORS) {
			return null;
		}
		if (event && event.message && IGNORE_ERRORS.includes(event.message)) {
			return null;
		}
		return event;
	},
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
		/api\.boomtrain\.com/i,
	],
});
const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

const SecretGate = () => (GameSettingsUtils.IS_ENABLED_SESSION ? <Outlet /> : <Fragment />);
createRoot(root).render(
	<Provider store={store}>
		<Router basename={"/"}>
			<GlobalPreloader />
			<ComingSoonModal />
			{/*<OffSeasonModal />*/}
			<VideoPlayerModal />
			<HowToPlayModal />
			<RegisterModal />
			<RecoverModal />
			<ConfirmSaveModal />
			<Suspense fallback={<PagePreloader />}>
				<CountriesNavigate>
					<GigyaGate>
						<Translations>
							<Routes>
								<Route element={<ProjectIndex />}>
									<Route path={"/"} index={true} element={<Landing />} />
									{/* Added for tests. Can be removed before release*/}
									{IS_UAT && (
										<Route element={<NotAuthOnlyRoute />}>
											<Route path={"/login_prev"} element={<Login />} />
										</Route>
									)}

									<Route path="/prizes" element={<Prizes />} />
									<Route path="/help" element={<Help />} />
									<Route path="/help/contact-us" element={<ContactUs />} />
									<Route path="/help/faqs" element={<FAQs />} />
									<Route path="/help/game-guidelines" element={<GameGuidelines />} />
									<Route path="/help/rules" element={<GameRules />} />
									<Route path="/help/prizes" element={<PrizesPool />} />
									<Route path="/help/terms" element={<Terms />} />

									<Route element={<SecretGate />}>
										<Route path={"/game"} element={<Brackets />} />
										<Route element={<NotAuthOnlyRoute />}>
											<Route path={"/backdoor"} element={<Backdoor />} />
										</Route>

										<Route element={<PrivateRoute />}>
											<Route path="/leagues" element={<Leagues />} />
											<Route path="/leagues/create" element={<CreateLeague />} />
											<Route path="/leagues/join" element={<JoinLeague />} />
											<Route path="/leagues/join/:code" element={<JoinLeague />} />
											<Route path="/league/:comp_id/ladder" element={<Ladder />} />
											<Route path="/league/:comp_id/about" element={<About />} />
											<Route path="/account/update" element={<YourAccount />} />
											<Route path="/account/nfl-account" element={<NFLAccount />} />
											<Route path="/account/communications" element={<Communications />} />
											<Route path="/account/deactivate-account" element={<DeactivateAccount />} />
										</Route>

										<Route path="/view/:userID" element={<SharedBrackets />} />
										<Route path={"/fans"} element={<FanPredictions />} />
										{/*<Route path="/help/privacy-policy" element={PrivacyPolicy} />*/}
									</Route>

									<Route element={<NotFound />} />
								</Route>
							</Routes>
						</Translations>
					</GigyaGate>
				</CountriesNavigate>
			</Suspense>
			<GlobalErrors />
		</Router>
	</Provider>
);
