import styled from "styled-components";

interface ITextarea {
	readonly max_width?: string;
}

export const Textarea = styled.textarea<ITextarea>`
	width: 100%;
	height: 150px;
	max-width: ${({max_width}: ITextarea) => max_width || "358px"};
	border: 1px solid #cccccc;
	border-radius: 4px;
	padding: 8px 16px;
	box-sizing: border-box;
	::placeholder {
		font-size: 14px;
	}

	@media screen and (max-width: 840px) {
		max-width: 100%;
	}
`;
