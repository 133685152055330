import {
	IBracket,
	IBracketRound,
	IDictionary,
	IPickAction,
	IPrediction,
	ISagaAction,
	ISquad,
	PickStatusEnum,
	IMatch,
} from "modules/types";
import {select, put, call} from "redux-saga/effects";
import {getBracketsStore} from "modules/selectors/brackets";
import {
	flatten,
	cloneDeep,
	find,
	uniq,
	keyBy,
	compact,
	mapValues,
	pickBy,
	values,
	difference,
	filter,
	map,
	flatMap,
	orderBy,
	groupBy,
} from "lodash";
import {getPredictionsSelector} from "modules/selectors/predictions";
import {updateBracketConfig, updateBracketsSavedStateAction} from "modules/actions/brackets";
import {DEFAULT_PREDICTION} from "modules/constants";
import {cleanUpPicksAction} from "modules/actions";
import {getRoundsMatchesSelector} from "modules/selectors";
import {getSquads} from "modules/selectors/squads";

const getSquadFinalInfo = (squadsList: ISquad[], knownSquadsIDs: string[], predictions: IDictionary<IPrediction>) => {
	const squadsListByID: IDictionary<ISquad> = keyBy(squadsList, "id");
	const currentPrediction: IPrediction = predictions[13];
	const finalSides: IDictionary<number> = {
		afc: predictions[11]?.seed || 0,
		nfc: predictions[12]?.seed || 0,
	};
	knownSquadsIDs.forEach((squadID) => {
		const squad: ISquad | undefined = squadsListByID[squadID];
		if (squad) {
			finalSides[squad.conference] = squad.seed;
		}
	});
	if (currentPrediction && currentPrediction.status === PickStatusEnum.RePick) {
		const predictionSquad = squadsListByID[currentPrediction.squad_id];
		finalSides[predictionSquad.conference] = currentPrediction.seed;
	}
	return finalSides;
};

//TODO: simplify function
export const fillBracketsByPredictionsList = function* (predictions: IDictionary<IPrediction>) {
	const {rounds} = yield select(getBracketsStore);
	const allMatches: IMatch[] = yield select(getRoundsMatchesSelector);
	const squads = yield select(getSquads);
	const bracketsRounds = flatMap(rounds, (round) => round.brackets);
	const bracketsArray = bracketsRounds
		// eslint-disable-next-line complexity,sonarjs/cognitive-complexity
		.map((bracket) => {
			if (!bracket.prevID) {
				return bracket;
			}

			const bracketPrediction = predictions[bracket.bracketID];
			const isRePick = bracketPrediction && bracketPrediction?.status === PickStatusEnum.RePick;

			// Fill bracket by real results when match will be known
			const bracketMatch = find(allMatches, {bracket_id: bracket.bracketID});
			// If no match check completed matches result from prev brackets
			const prevMatches = allMatches
				.filter((match) => bracket.prevID?.indexOf(match.bracket_id) !== -1 && match.status === "complete")
				.map((match) => {
					const {away_game_points, home_game_points, home_squad_id, away_squad_id} = match;
					if (home_game_points > away_game_points) {
						return home_squad_id;
					}
					return away_squad_id;
				});

			const matchSquadsSeeds = orderBy(
				squads.filter(({id}) => prevMatches.includes(id)),
				"seed",
				["desc"]
			).map((squad) => squad.seed);

			const predictionsPrev = orderBy(
				Object.values(predictions).filter(
					(prediction) =>
						bracket.prevID?.indexOf(prediction.bracket_id) !== -1 &&
						prediction.status !== PickStatusEnum.Failed
				),
				"seed",
				["desc"]
			);

			let predictionsPrevSeeds = predictionsPrev.map((prediction) => prediction.seed);

			const roundRePicks = Object.values(
				pickBy(predictions, {
					round: bracket.roundID,
					conference: bracket.conference,
					status: PickStatusEnum.RePick,
				})
			);

			if (isRePick) {
				if (bracket.bracketID === 13 && bracketPrediction.conference === "afc") {
					bracket.seeds[0] = [bracketPrediction.seed];
					return bracket;
				} else if (bracket.bracketID === 13 && bracketPrediction.conference === "nfc") {
					bracket.seeds[1] = [bracketPrediction.seed];
					return bracket;
				}
				if (!bracket.seeds[0].length) {
					bracket.seeds[0] = [bracketPrediction.seed];
				} else {
					// Todo: investigate this error
					// @ts-ignore
					if (bracket.seeds[0] !== [bracketPrediction.seed]) {
						bracket.seeds[1] = [bracketPrediction.seed];
					}
				}
				return bracket;
			}

			if (matchSquadsSeeds.length && !roundRePicks.length) {
				predictionsPrevSeeds = uniq([...predictionsPrevSeeds, ...matchSquadsSeeds])
					.sort()
					.reverse();
			}

			if (bracketMatch) {
				const matchSquadsIDs = bracketMatch
					? compact([bracketMatch.away_squad_id, bracketMatch.home_squad_id])
					: [];

				const matchSquadsID = squads
					.filter(({id}) => matchSquadsIDs.includes(id))
					.sort((a, b) => b.seed - a.seed)
					.map((squad) => squad.id);
				const matchLowestSeed = matchSquadsID[0];
				const matchHighSeed = matchSquadsID[1];

				if (matchLowestSeed && matchHighSeed) {
					bracket.seeds[0] = [matchLowestSeed];
					bracket.seeds[1] = [matchHighSeed];
					return bracket;
				}
			}
			// Get predictions that possible to add to this bracket (by bracket.prevID) and sort by seed to have 1
			// element as lowest
			const predictionLowSeed = predictionsPrevSeeds[0] || 0;
			const predictionsHighSeed = predictionsPrevSeeds[1] || 0;
			// Customisation for Divisional round (1-st seed should play with lowest seed)
			if ([7, 9].indexOf(bracket.bracketID) > -1) {
				bracket.seeds[0] = [predictionsPrevSeeds[0]];
			} else if ([8, 10].indexOf(bracket.bracketID) > -1) {
				bracket.seeds[0] = [predictionsPrevSeeds[1]];
				bracket.seeds[1] = [predictionsPrevSeeds[2]];
			} else if (bracket.bracketID === 13) {
				const finalSeeds = getSquadFinalInfo(squads, prevMatches, predictions);
				bracket.seeds[0] = [finalSeeds.afc];
				bracket.seeds[1] = [finalSeeds.nfc];
			} else {
				// Show low prediction seed to left side and high to right side
				bracket.seeds[0] = predictionLowSeed ? [predictionLowSeed] : [];
				bracket.seeds[1] = predictionsHighSeed ? [predictionsHighSeed] : [];
			}
			// If two prediction one the same bracket
			const finalBracketSeeds = flatten(bracket.seeds);
			const roundPredictions = pickBy(predictions, {
				round: bracket.roundID,
				conference: bracket.conference,
				status: PickStatusEnum.Picked,
			});
			const roundPredictionsSees = values(roundPredictions).map((pr) => pr.seed);
			if (roundPredictionsSees.length === 2 && !difference(roundPredictionsSees, finalBracketSeeds).length) {
				mapValues(roundPredictions, (pr) => {
					predictions[pr.bracket_id] = DEFAULT_PREDICTION;
				});
			}
			return bracket;
		});

	const brackets = keyBy(bracketsArray, "bracketID");

	// Put changed brackets back to config
	const configData = rounds.map((round: IBracketRound) => {
		return {
			...round,
			brackets: round.brackets.map((bracket) => brackets[bracket.bracketID]),
		};
	});
	return {
		brackets,
		configData,
		predictions,
	};
};

/**
 * Move Pick description. This saga will re-build full bracket after each predictions.
 * We will build bracket by all predictions in list in detect predictions round by bracket.prevID from brackets config.
 * Note: teams display logic located in 'getBracketTeamsSelector'.
 * @param payload -> current pick data <IMakePickAction>
 */
export const movePickSaga = function* ({payload}: ISagaAction<IPickAction>) {
	//First we after every prediction we check and clean next brackets predictions
	const predictionsStore = yield call(cleanUpPredictionsSaga, payload);
	// Start bracket builder
	const {brackets, configData, predictions} = yield call(fillBracketsByPredictionsList, predictionsStore);
	// Fix predictions brackets id if prediction auto moved to another bracket
	const cleanedPredictions = cleanUpPredictionsBracketID(predictions, brackets);
	yield put(cleanUpPicksAction(cleanedPredictions));
	yield put(updateBracketConfig(configData));
	yield put(updateBracketsSavedStateAction(false));
};

export const cleanUpPredictionsSaga = function* (prediction: IPickAction) {
	const {conference, seed, round, prevBracketsIDS} = prediction;
	const predictionsStore = yield select(getPredictionsSelector);

	const prevPick = predictionsStore[prediction.bracketID];

	if (prevPick) {
		const opponents = filter(
			predictionsStore,
			(pr: IPrediction) =>
				pr.round > prediction.round && (pr.conference === prevPick.conference || pr.round === 22)
		);
		map(opponents, (opponent) => {
			if (predictionsStore[opponent.bracket_id]) {
				delete predictionsStore[opponent.bracket_id];
			}
		});
	}

	// Set new pick
	predictionsStore[prediction.bracketID] = {
		round: prediction.round,
		squad_id: prediction.squad_id,
		seed: prediction.seed,
		conference: prediction.conference,
		status: PickStatusEnum.Picked,
		bracket_id: prediction.bracketID,
		squadName: prediction.squadName,
		prevBracketsIDS: prevBracketsIDS,
		opponent_squad_id: prediction?.opponent_squad_id,
	};

	const clonedPredictions = cloneDeep(predictionsStore);
	const predictionsValues = values(clonedPredictions);
	const filteredPredictions = filter(predictionsValues, (pr) => pr.conference === conference && pr.round >= round);
	const groupedPredictions = groupBy(filteredPredictions, "round");
	const sortedGroupedPredictions = orderBy(values(groupedPredictions), (group) => group[0]?.round, "asc");

	sortedGroupedPredictions.reduce((com, nextPredictions) => {
		const nextSeeds = nextPredictions.map((prev) => prev.seed);
		const prevSeeds = com.map((prev) => prev.seed);

		if (round === 19) {
			nextSeeds.push(1);
			prevSeeds.push(1);
		}

		if (!nextSeeds.includes(seed)) {
			const toCleanUp = find(nextPredictions, (pr) => !prevSeeds.includes(pr.seed));
			if (toCleanUp) {
				predictionsStore[toCleanUp.bracket_id] = DEFAULT_PREDICTION;
			}
		}

		return nextPredictions;
	}, []);

	return predictionsStore;
};

export const cleanUpPredictionsBracketID = function (
	predictions: IDictionary<IPrediction>,
	bracket: IDictionary<IBracket>
) {
	const newPredictions = cloneDeep(predictions);
	// Clean up works only for Divisional round if pick bracket position was changed after last pick
	values(bracket).forEach((bracket) => {
		const {roundID, conference, seeds, bracketID} = bracket;
		if ([7, 9].indexOf(bracketID) === -1) {
			return;
		}
		const bracketSeeds = flatten(seeds);
		const bracketPrediction = find(
			predictions,
			(prediction) =>
				bracketSeeds.indexOf(prediction.seed) > -1 &&
				prediction.round === roundID &&
				prediction.conference === conference &&
				prediction.bracket_id !== bracketID
		);
		if (bracketPrediction && roundID === bracketPrediction.round && bracketPrediction.bracket_id) {
			newPredictions[bracketID] = {
				...bracketPrediction,
				bracket_id: bracketID,
			};
			newPredictions[bracketPrediction.bracket_id] = DEFAULT_PREDICTION;
		}
	});
	return newPredictions;
};
