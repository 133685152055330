import React from "react";
import styled from "styled-components";
import theme from "assets/css/theme";

const WidgetContent = styled.div`
	h3 {
		font-family: AllProDisaplayB-Medium;
		font-size: 30px;
		letter-spacing: -0.1px;
		line-height: 46px;
		color: #333;
		margin-bottom: 20px;
		text-transform: uppercase;
		padding: 0 20px;
	}

	img {
		width: 100%;
		height: 50%;
		margin-bottom: 20px;
	}

	h6 {
		font-size: 16px;
		font-family: ${theme.base_bold_font};
		color: #333;
		padding: 0 20px;
		margin-bottom: 5px;
	}

	p {
		font-size: 14px;
		font-family: ${theme.base_font};
		color: #555;
		padding: 0 20px;
	}
`;

interface IProps {
	content: string;
}

export const PrizeWidgetContent: React.FC<IProps> = ({content}) => (
	<WidgetContent dangerouslySetInnerHTML={{__html: content}} />
);
