import React from "react";
import styled from "styled-components";
import theme from "assets/css/theme";

const WidgetContent = styled.div`
	dl {
		height: 40px;
		border-bottom: 1px solid #eee;
		display: flex;
		align-items: center;
		margin: 0 10px;
		padding: 0 10px;
	}
	dt,
	dd {
		color: #333;
		font-size: 12px;
		line-height: 14px;
		text-align: left;
		width: 50%;
	}
	dt {
		font-family: ${theme.base_bold_font};
	}
	dd {
		font-family: ${theme.base_font};
	}

	h6 {
		color: #333;
		font-size: 14px;
		line-height: 22px;
		font-family: ${theme.base_font};
		padding: 0 20px;
		margin: 20px 0;
	}

	p {
		padding: 0 20px;
		color: #555;
		font-size: 14px;
		line-height: 22px;
	}
`;

interface IProps {
	content: string;
}

export const ScoreWidgetContent: React.FC<IProps> = ({content}: IProps) => (
	<WidgetContent dangerouslySetInnerHTML={{__html: content}} />
);
