import {createReducer} from "redux-act";
import {ILeagueStateReducer} from "modules/types";
import {clearLeagues, showMyLeagues, showMyLeaguesFailed, showMyLeaguesSuccess} from "modules/actions";

const initialState: ILeagueStateReducer = {
	items: [],
	next: false,
	is_data_requested: false,
};

export const my_leagues = createReducer({}, initialState)
	.on(showMyLeagues, (state: ILeagueStateReducer) => ({
		...state,
		is_data_requested: true,
	}))
	.on(showMyLeaguesSuccess, (state: ILeagueStateReducer, payload: ILeagueStateReducer) => ({
		...state,
		items: [...payload.items],
		next: payload.next,
		is_data_requested: false,
	}))
	.on(clearLeagues, () => ({
		...initialState,
	}))
	.on(showMyLeaguesFailed, (state: ILeagueStateReducer) => ({
		...state,
		is_data_requested: false,
	}));
