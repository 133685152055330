import React, {useCallback, useState, useEffect} from "react";
import styled from "styled-components";
import logo from "assets/img/NFLShield.svg";
import {useI18n} from "modules/hooks";
import theme from "assets/css/theme";
import {CheckboxStyled, Input, Label} from "components/Form";
import {RegularButton} from "components/Buttons";
import {REGISTRATION_FORM_ERRORS, REGISTRATION_FORM_STATE} from "modules/constants";
import {validateField} from "modules/utils/helpers";
import {noop, compact} from "lodash";
import {IDictionary} from "modules/types";
import {useDispatch, useSelector} from "react-redux";
import {registerAction} from "modules/actions/auth";
import {createConnextraScriptTag} from "modules/utils";
import {Modal} from "components/Modal";
import {getIsNeedToRegisterSelector} from "modules/selectors";

const FormWrapper = styled.div`
	padding: 0 20px 50px;
	background: #ffffff;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

const Form = styled.form``;
const Logo = styled.img`
	width: 120px;
`;
const Title = styled.div`
	margin: 20px 0;
	text-align: center;
	font-size: 16px;
	line-height: 20px;
	font-family: ${theme.base_bold_font};
`;
const SubLabel = styled.div`
	font-size: 12px;
	color: #757575;
	margin-bottom: 5px;
`;
const FormLine = styled.div`
	margin-bottom: 20px;
`;
const CheckBox = styled.div`
	display: flex;
	align-items: flex-start;
	font-size: 12px;
	color: #555555;
	line-height: 1.5;
`;
const ErrorText = styled.div`
	padding-top: 5px;
	font-size: 12px;
	color: #d00b0b;
`;

const trackCaAnalytics = () => {
	if (import.meta.env.VITE_NAME === "nfl-ca") {
		if (window.fbq) {
			window.fbq("track", "Schedule");
		}
		if (window.gtag) {
			window.gtag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflsb0/nflsb003+standard",
			});
		}
	}
};

const trackAuAnalytics = () => {
	if (import.meta.env.VITE_NAME === "nfl-au" && window.fbq) {
		window.fbq("track", "CompleteRegistration");
	}
};

export const RegisterModal = () => {
	const dispatch = useDispatch();
	const isOpen = useSelector(getIsNeedToRegisterSelector);
	const [formValues, setFormValues] = useState(REGISTRATION_FORM_STATE);
	const [formErrors, setFormErrors] = useState(REGISTRATION_FORM_ERRORS);
	const titleTranslation = useI18n("register_header_label_team_name");
	const teamNamePlaceholder = useI18n("register_form_placeholder_team_name");
	const teamNameLabel = useI18n("register_form_label_team_name");
	const termsText = useI18n("register_form_label_terms");
	const emailText = useI18n("account_form_label_email_receive");
	const optInText = useI18n("register_form_label_opt_in");

	useEffect(() => {
		if (isOpen) {
			createConnextraScriptTag(
				"https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_regstart"
			);
		}
	}, [isOpen]);

	const clearError = (name: string) => {
		setFormErrors({
			...formErrors,
			[name]: "",
		});
	};
	const formChangeHandler = (e: React.FormEvent<HTMLFormElement>) => {
		const {name, value, checked, type} = e.target as HTMLFormElement;
		const formValue = type === "checkbox" ? Number(checked) : value;
		setFormValues({
			...formValues,
			[name]: formValue,
		});
		if (type !== "checkbox") {
			clearError(name);
			// eslint-disable-next-line sonarjs/no-duplicated-branches
		} else if (formValue) {
			clearError(name);
		}
	};
	const validateHandle = useCallback(
		(e: React.BaseSyntheticEvent) => {
			const error = validateField(e.target, formValues);
			if (error) {
				console.log(error.name);
				setFormErrors({
					...formErrors,
					[error.name]: error.error,
				});
			}
		},
		[formErrors, formValues]
	);
	const clearFieldHandler = ({currentTarget}: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const {element_name} = currentTarget.dataset;
		if (element_name) {
			setFormValues({
				...formValues,
				[element_name]: "",
			});
		}
	};

	const submitFormHandler = (e: React.FormEvent) => {
		e.preventDefault();
		trackCaAnalytics();
		trackAuAnalytics();
		dispatch(registerAction(formValues));
	};
	const isValidState = (data: IDictionary<unknown>) => compact(Object.values(data)).length > 0;
	const isButtonsDisabled = [isValidState(formErrors), !formValues.team_name, !formValues.terms].some(Boolean);

	if (!isOpen) {
		return null;
	}
	return (
		<Modal onClose={noop} isCloseable={false} width={"340px"} height={"95%"}>
			<FormWrapper>
				<Logo src={logo} alt={"NFL logo"} />

				<Title>{titleTranslation}</Title>
				<Form onChange={formChangeHandler} onSubmit={submitFormHandler}>
					<FormLine>
						<Label>{teamNameLabel}</Label>
						<SubLabel>{window.getTranslations("register_text_text_teamname")}</SubLabel>
						<Input
							name="team_name"
							type="text"
							value={formValues.team_name}
							is_error={false}
							placeholder={teamNamePlaceholder}
							onBlur={validateHandle}
							data-validations="required, max40"
							clearField={clearFieldHandler}
						/>
						{formErrors.team_name ? <ErrorText>{formErrors.team_name}</ErrorText> : null}
					</FormLine>
					<FormLine>
						<CheckBox>
							<div>
								<CheckboxStyled
									// @ts-ignore
									onChange={validateHandle}
									name={"terms"}
									is_checked={Boolean(formValues.terms)}
									data-validations="checkedOnly"
								/>
							</div>
							{/* Reason: This is value from our Backend and we trust this data */}
							{/* eslint-disable-next-line react/no-danger */}
							<div dangerouslySetInnerHTML={{__html: termsText}} />
						</CheckBox>
						{formErrors.terms ? <ErrorText>{formErrors.terms}</ErrorText> : null}
					</FormLine>
					<FormLine>
						<CheckBox>
							<div>
								<CheckboxStyled
									name={"email_notifications"}
									is_checked={Boolean(formValues.email_notifications)}
								/>
							</div>
							<div>{emailText}</div>
						</CheckBox>
					</FormLine>

					<FormLine>
						<CheckBox>
							<div>
								<CheckboxStyled
									name={"client_notifications"}
									is_checked={Boolean(formValues.client_notifications)}
								/>
							</div>
							{/* Reason: This is value from our Backend and we trust this data */}
							{/* eslint-disable-next-line react/no-danger */}
							<div dangerouslySetInnerHTML={{__html: optInText}} />
						</CheckBox>
					</FormLine>

					<RegularButton disabled={isButtonsDisabled} data-food_track_id={"/nflsb0/nflsb003+standard"}>
						{window.getTranslations("register_button_button_start")}
					</RegularButton>
				</Form>
			</FormWrapper>
		</Modal>
	);
};
