import {TConference} from "modules/types/brackets";

export interface IPickAction {
	round: number;
	squad_id: string;
	bracketID: number;
	seed: number;
	conference: TConference;
	squadName: string;
	prevBracketsIDS?: number[];
	opponent_squad_id?: string;
}
export enum PickStatusEnum {
	Picked = "picked",
	Correct = "correct",
	Failed = "failed",
	RePick = "re-pick",
	Default = "default",
}
export interface IPrediction {
	id: number;
	round: number;
	squad_id: string;
	points: number;
	max_points: number;
	seed: number;
	conference: TConference;
	status: PickStatusEnum;
	bracket_id: number;
	real_bracket_id: number;
	opponent_squad_id?: string;
}

export interface ISavePredictionsList {
	round: number;
	squad_id: string;
	bracket_id: number;
	opponent_squad_id: string | null;
}

export interface ISavePredictionsParams {
	predictions: ISavePredictionsList[];
}

export interface IFetchSharedPredictions {
	user_id: string;
}
