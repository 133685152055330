import React, {useEffect, useState} from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/NFLShield.svg";
import {useDispatch, useSelector} from "react-redux";
import {
	getUserDataSelector,
	isAuthedSelector,
	isComingSoonSelector,
	getIsNeedToRegisterSelector,
} from "modules/selectors";
import {RegularButton} from "components/Buttons";
import theme from "assets/css/theme";
import {updateUserAction} from "modules/actions";
import {createConnextraScriptTag} from "modules/utils";
import {SSOUtils} from "modules/utils/sso";
import {Storage} from "modules/utils/Storage";
import {GameSettingsUtils} from "modules/utils/secret";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 20px;
	gap: 20px;
	button {
		max-width: 250px;
	}
`;
const Logo = styled.img`
	width: 100%;
	max-width: 120px;
`;

const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
`;

const Text = styled.div`
	font-size: 15px;
	text-align: center;
	line-height: 24px;
	font-family: ${theme.base_font};
`;

interface IProps {
	openOutsideState: boolean;
}

const LoginButton = styled.button`
	color: ${theme.primary};
	box-shadow: none;
	background: none;
	outline: none;
	border: none;
	font-size: inherit;
	font-family: inherit;
`;

export const WelcomeModal: React.FC<IProps> = ({openOutsideState}) => {
	const dispatch = useDispatch();
	const user = useSelector(getUserDataSelector);
	const isLoggedIn = useSelector(isAuthedSelector);
	const isComingSoon = useSelector(isComingSoonSelector);
	const isRegister = useSelector(getIsNeedToRegisterSelector);
	const isOpen = !user.welcome_popup && !Storage.GET("welcome_popup");
	const [openState, changeOpenState] = useState(isOpen);

	useEffect(() => {
		if (openOutsideState) {
			changeOpenState(true);
		}
	}, [openOutsideState]);

	const closeClick = () => {
		changeOpenState(false);
		if (window.ftag) {
			window.ftag("event", "conversion", {
				allow_custom_scripts: true,
				send_to: "DC-10179195/nflsb0/2023n003+standard",
			});
		}
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_pickspage?AccountID=${user.id}`
		);
		if (user.id) {
			if (!user.welcome_popup) {
				dispatch(
					updateUserAction({
						welcome_popup: 1,
					})
				);
			}
		} else {
			Storage.SET("welcome_popup", "true");
		}
	};

	if (!GameSettingsUtils.IS_ENABLED_SESSION || isComingSoon || isRegister) {
		return null;
	}

	return openState ? (
		<Modal onClose={closeClick} isCloseable={true} isCloseOutside={true} style={{maxWidth: "500px"}}>
			<Wrapper>
				<Logo src={logo} alt={"NFL logo"} />
				<Title>{window.getTranslations("modal_header_welcome")}</Title>
				<Text>{window.getTranslations("modal_body_welcome")}</Text>
				<RegularButton onClick={closeClick}>
					{window.getTranslations("register_form_start_picking")}
				</RegularButton>

				{!isLoggedIn && (
					<Text>
						{window.getTranslations("modal_body_welcome_not_logged_in")}
						<LoginButton onClick={SSOUtils.LOGIN}>
							{window.getTranslations("modal_body_welcome_log_in")}
						</LoginButton>
					</Text>
				)}
			</Wrapper>
		</Modal>
	) : null;
};
