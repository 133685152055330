import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/logo-tall.svg";
import {useI18n} from "modules/hooks";
import theme from "assets/css/theme";
import deLogo from "assets/img/de-logo-tall.svg";
import {RegularButton} from "components/Buttons";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;

	.RegularButton {
		margin-top: 20px;
		max-width: 200px;
	}
`;
const Logo = styled.img`
	width: 100%;
	max-width: 175px;
	margin-bottom: 20px;
`;
const Title = styled.div`
	font-size: 26px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 21px;
	text-transform: uppercase;
`;
const Text = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 26px;
	max-width: 500px;
`;
const WrapperWarningConnectWithSSO = styled.div`
	.modal-wrapper {
		z-index: 10 !important;
	}
`;

interface IWarningConnectWithSSOModal {
	isShow: boolean;
}

export const WarningConnectWithSSOModal: React.FC<IWarningConnectWithSSOModal> = ({isShow}) => {
	const closeClickPlaceholder = () => window.location.reload();
	const titleText = useI18n("landing.modal.sso_error_title");
	const text = useI18n("landing.modal.sso_error_text");
	const headerLogo = import.meta.env.VITE_NAME === "nfl-de" ? deLogo : logo;
	return isShow ? (
		<WrapperWarningConnectWithSSO>
			<Modal onClose={closeClickPlaceholder} isCloseable={false} width="750px">
				<Wrapper>
					<Logo src={headerLogo} alt={"NFL logo"} />
					<Title>{titleText}</Title>
					<Text>{text}</Text>
					<RegularButton className="RegularButton" onClick={closeClickPlaceholder}>
						Ok
					</RegularButton>
				</Wrapper>
			</Modal>
		</WrapperWarningConnectWithSSO>
	) : null;
};
