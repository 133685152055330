import {getActualRound, getRounds, getRoundsMatchesSelector} from "modules/selectors/rounds";
import {getSquads} from "modules/selectors/squads";
import {IBracket, IBracketTeams, TConference, TSeed} from "modules/types/brackets";
import {createSelector} from "reselect";
import {IDictionary, IRound, ISquad, IStore, MatchStatusEnum} from "modules/types";
import {find, size, filter, identity, at, get, isNumber} from "lodash";
import {getTeamLadderTeamsList} from "modules/selectors/ladder";
import {isBracketByLadderEnabled} from "modules/selectors/global";
import {getBracketPredictionByIDSelector} from "modules/selectors/predictions";

export const getBracketsStore = ({brackets}: IStore) => brackets;

export const getBetMatches = createSelector(
	getActualRound,
	getBracketPredictionByIDSelector,
	(actualRound, getBracketPredictionByID) => {
		return filter(actualRound?.matches, (match) =>
			[
				match.status === MatchStatusEnum.Scheduled,
				...at(match, [
					"away_squad_id",
					"away_squad_odds",
					"away_squad_odds",
					"home_squad_id",
					"home_squad_odds",
					"home_squad_odds",
				]),
			].every(identity)
		).map((match) => ({
			...match,
			prediction: getBracketPredictionByID(match.bracket_id),
		}));
	}
);

export const getFlatRounds = createSelector(getBracketsStore, ({rounds}) => {
	const bracketsSize = size(rounds) - 1;
	const middleBracketsSize = Math.ceil(bracketsSize / 2);

	const flatBracketsList: typeof rounds = [];

	for (let leftIndex = 0; leftIndex <= middleBracketsSize; leftIndex++) {
		const left = rounds[leftIndex];
		const rightIndex = bracketsSize - leftIndex;
		const right = rounds[rightIndex];

		if (left) {
			flatBracketsList.push(left);
		}

		if (right && leftIndex !== rightIndex) {
			flatBracketsList.push(right);
		}
	}

	return flatBracketsList;
});

const checkIsHotFix = (homeSeed: number | string, awaySeed: number | string) =>
	(Number(homeSeed) > 7 && Number(awaySeed) > 7) || (!isNumber(homeSeed) && !isNumber(awaySeed));

export const getBracketTeamsSelector = createSelector(
	[getRounds, getSquads, getTeamLadderTeamsList, isBracketByLadderEnabled],
	(rounds, squads, ladderTeamsList, isBracketByLadder) =>
		(bracketData: IBracket): IBracketTeams => {
			const round = getBracketRoundById(rounds, bracketData.roundID);
			const conference = bracketData.conference;
			let squadsList = squads;
			if (isBracketByLadder) {
				squadsList = ladderTeamsList;
			}
			if (!round) {
				return {};
			}
			const {seeds} = bracketData;
			const homeSeed = seeds[0];
			const awaySeed = seeds[1];

			//TODO: hot fix to show real teams https://fanhub.atlassian.net/browse/NFLSBC20-268
			// The bracket display wrong picks because no checks for not active squads. We need to display  not active
			// fro completed and active for future
			if (checkIsHotFix(homeSeed[0], awaySeed[0])) {
				return {
					homeSquad: squads.find((squad) => squad.id === homeSeed[0]),
					awaySquad: squads.find((squad) => squad.id === awaySeed[0]),
				};
			}

			let homeSquad = getBracketSquadBySeeds(squadsList, homeSeed, conference);
			let awaySquad = getBracketSquadBySeeds(squadsList, awaySeed, conference);

			if (!conference) {
				homeSquad = getBracketSquadBySeeds(squadsList, homeSeed, "afc");
				awaySquad = getBracketSquadBySeeds(squadsList, awaySeed, "nfc");
			}
			return {
				homeSquad,
				awaySquad,
			};
		}
);

export const getBracketRoundById = (rounds: IRound[], id: number) => find(rounds, {id});
export const getBracketSquadBySeeds = (squads: ISquad[], seeds: TSeed[], conference: TConference) =>
	find(squads, (squad: ISquad) =>
		Boolean(squad.seed && seeds.indexOf(squad.seed) > -1 && squad.conference === conference)
	);

export const getBracketsIsSavedSelector = createSelector(getBracketsStore, ({isSaved}) => isSaved);

export const getBracketMatchByBracketIDSelector = createSelector(
	getRoundsMatchesSelector,
	(matches) => (bracket_id: number) => find(matches, {bracket_id})
);

export const getSelectedBracketIDSelector = createSelector(
	getBracketsStore,
	({selectedBracketID}) => selectedBracketID
);

const actualBracketIDByRoundMap: IDictionary<number> = {
	19: 1,
	20: 7,
	21: 11,
	22: 13,
};

export const getFirstScheduledBracketIDSelector = createSelector(getActualRound, (round) => {
	const roundID = get(round, "id", 19);
	return actualBracketIDByRoundMap[roundID];
});
