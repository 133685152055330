import React from "react";
import {
	ErrorField,
	Input,
	Label,
	Radio,
	RegularButton,
	Select,
	Textarea,
	LeagueToggleButton,
	CreateLeagueWrapper,
} from "components";
import {bindAll, each, find, map} from "lodash";
import styled from "styled-components";
import theme from "assets/css/theme";
import {IRound, IMyLeague, IFunc0, IStore} from "modules/types";
import {connect} from "react-redux";
import {createLeague, fetchRoundsAction, updateLeague} from "modules/actions";
import {getLeagueScheduledRound, getLeagueScheduledRounds} from "modules/selectors";
import {createConnextraScriptTag, getOmnitureTracking} from "modules/utils";

const Title = styled.h4`
	color: #333333;
	font-size: 28px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 820px) {
		font-size: 20px;
		line-height: 24px;
	}
`;

const Description = styled.div`
	color: #555555;
	font-size: 14px;
	font-family: ${theme.base_font};
	line-height: 22px;
`;

const CreateForm = styled.form``;

const ElementWrapper = styled.div`
	margin: 20px 0;
`;

const WarningText = styled.div`
	font-size: 15px;
	line-height: 26px;
`;

interface ICreateLeagueForm {
	name: string;
	privacy?: string;
	start_round?: string;
	description?: string;
}

interface IState {
	form_element: ICreateLeagueForm;
	form_errors: {
		name: string;
	};
}

interface IProps {
	rounds: IRound[];
	actual_round?: IRound;
	createLeague: typeof createLeague;
	fetchRoundsAction: typeof fetchRoundsAction;
	updateLeague: typeof updateLeague;
	league?: IMyLeague;
	closeForm?: IFunc0;
	omniture_tracking: any;
	user: any;
}

class CreateLeagueFormComponent extends React.Component<IProps, IState> {
	public state = {
		form_element: {
			name: "",
			privacy: "private",
			start_round: "",
			description: "",
		},
		form_errors: {
			name: "",
		},
	};

	constructor(props: IProps) {
		super(props);

		bindAll(this, ["handleChange", "handleSubmit", "clearField", "initFormElement"]);
	}

	public initFormElement() {
		const {league} = this.props;

		if (league && league.id) {
			this.setState({
				form_element: {
					name: league.name,
					privacy: league.privacy,
					start_round: league.start_round.toString(),
					description: league.description,
				},
			});
		}
	}

	/**
	 * @ignore
	 */
	public componentDidMount(): void {
		const {fetchRoundsAction: fetchRounds} = this.props;
		this.checkRound();
		fetchRounds();
		this.initFormElement();
	}

	public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
		if (prevProps.league !== this.props.league) {
			this.initFormElement();
		}
		if (!prevProps.actual_round && this.props.actual_round) {
			this.checkRound();
		}
	}

	public render() {
		const {form_errors, form_element} = this.state;
		const {rounds, league, closeForm, omniture_tracking, user} = this.props;

		return rounds.length ? (
			<CreateLeagueWrapper>
				{league && omniture_tracking("leagues", "settings", user)}
				<Title>
					{window.getTranslations(
						league ? "myleagues_header_update_league" : "competitioncreate_form_button_create"
					)}
					{league && (
						<LeagueToggleButton onClick={closeForm}>
							{window.getTranslations("leagueinvite_form_button_cancel")}
						</LeagueToggleButton>
					)}
				</Title>
				<Description>
					{window.getTranslations("createleague_form_text_form")}
					{/*<p>Fill out the short form below to create</p>*/}
					{/*your very own league.*/}
				</Description>
				<CreateForm onSubmit={this.handleSubmit} onChange={this.handleChange}>
					<ElementWrapper>
						<Label>{window.getTranslations("competitioncreate_form_label_league_name")}</Label>
						{form_errors.name !== "" ? <ErrorField>{form_errors.name}</ErrorField> : null}
						<Input
							name="name"
							type="text"
							is_error={form_errors.name !== ""}
							placeholder={window.getTranslations("competitioncreate_form_label_league_name")}
							clearField={this.clearField}
						/>
					</ElementWrapper>

					<ElementWrapper>
						<Label>{window.getTranslations("leagueinvite_dropdown_starting_Round")}*</Label>
						<Select name="start_round" defaultValue={form_element.start_round}>
							{map(rounds, (round) => (
								<option key={round.id} value={round.id}>
									{window.getTranslations(`game_week_label_${round.id}`)}
								</option>
							))}
						</Select>
					</ElementWrapper>
					<ElementWrapper>
						<Label>{window.getTranslations("fld_privacy")}*</Label>
						<Radio
							name="privacy"
							current_value={form_element.privacy}
							options={[
								{
									value: "private",
									text: window.getTranslations("competitioncreate_form_toggle_private"),
								},
								{
									value: "public",
									text: window.getTranslations("competitioncreate_form_toggle_public"),
								},
							]}
						/>
					</ElementWrapper>

					<ElementWrapper>
						<Label>{window.getTranslations("leaguecreate_form_label_description")}</Label>
						<Textarea
							name="description"
							maxLength={300}
							placeholder={window.getTranslations("leaguecreate_form_text_checkbox_area")}
						/>
					</ElementWrapper>
					<RegularButton max_width="200px">
						{window.getTranslations(league ? "account_form_button_update" : "mycomps_header_button_create")}
					</RegularButton>
				</CreateForm>
			</CreateLeagueWrapper>
		) : (
			<CreateLeagueWrapper>
				<WarningText>{window.getTranslations("leagues_alert_closed")}</WarningText>
			</CreateLeagueWrapper>
		);
	}

	private handleChange(event: React.FormEvent<HTMLFormElement>) {
		const {name, value} = event.target as HTMLFormElement;

		const {form_element} = this.state;

		this.validateFormItem(name, value as string);

		this.setState({
			form_element: {
				...form_element,
				[name]: value,
			},
		});

		this.setState({
			form_errors: {
				name: "",
			},
		});
	}

	private handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		const {createLeague: create, updateLeague: update, league, user} = this.props;
		const {form_element} = this.state;

		this.validateForm();

		if (!this.check_is_form_have_error) {
			if (league) {
				update({
					id: league.id,
					...form_element,
				});
			} else {
				console.log(form_element);
				createConnextraScriptTag(
					`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_leagueconfirm?AccountID=${
						user.id as number
					}`
				);
				create(form_element);
			}
		}
	}

	private validateForm() {
		const {form_element} = this.state;

		each(form_element, (value, name) => {
			this.validateFormItem(name, value);
		});
	}

	private validateFormItem(name: string, value: string) {
		if (name === "name" && !value) {
			this.setState({
				form_errors: {
					name: window.getTranslations("register_form_label_required") || "",
				},
			});
		}
	}

	private clearField({currentTarget}: React.MouseEvent<HTMLDivElement, MouseEvent>) {
		const {element_name} = currentTarget.dataset;
		const {form_element} = this.state;

		if (element_name) {
			this.setState({
				form_element: {
					...form_element,
					[element_name]: "",
				},
			});
		}
	}

	private get check_is_form_have_error() {
		const {form_errors} = this.state;
		return Boolean(find(form_errors, (error) => error !== ""));
	}

	private checkRound() {
		const {actual_round} = this.props;
		const {form_element} = this.state;
		if (form_element.start_round === "" && actual_round) {
			form_element.start_round = actual_round.id.toString();
			this.setState({form_element});
		}
	}
}

const mapStateToProps = (state: IStore) => ({
	rounds: getLeagueScheduledRounds(state),
	actual_round: getLeagueScheduledRound(state),
	omniture_tracking: getOmnitureTracking,
	user: state.user,
});

const mapDispatchToProps = {
	createLeague,
	updateLeague,
	fetchRoundsAction,
};

export const CreateLeagueForm = connect(mapStateToProps, mapDispatchToProps)(CreateLeagueFormComponent);
export default CreateLeagueForm;
