import React from "react";
import styled from "styled-components";
import {IDictionary, IMatch, IPrediction, ISquad, MatchStatusEnum, PickStatusEnum} from "modules/types";
import {get, last} from "lodash";
import theme from "assets/css/theme";
import {useDispatch, useSelector} from "react-redux";
import {makePickAction, rePickAction} from "modules/actions/predictions";
import {TConference} from "modules/types/brackets";
import {DEFAULT_PREDICTION, SQUAD_COLORS} from "modules/constants";
import {useMaxPoints} from "modules/hooks";
import {Exist} from "components/Exist";
import {media} from "assets/css/media";
import {useLocation} from "react-router-dom";
import {setSelectedBracketIDAction} from "modules/actions/brackets";
import {getBracketPredictionResultByIDSelector} from "modules/selectors";

const Seed = styled.div`
	position: absolute;
	top: -1px;
	left: -1px;
	width: 20px;
	height: 20px;
	font-family: "AllProDisaplayB-Bold", sans-serif;
	font-style: italic;
	font-size: 12px;
	padding: 3px 0 0 5px;
	border-bottom-right-radius: 10px;
`;
const PotentialPoints = styled.div`
	font-size: 12px;
	color: #fff;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	font-family: ${theme.secondary_bold_font};
	font-style: italic;
`;
const SquadLogo = styled.img`
	position: relative;
	z-index: 2;
	&.titans {
		@media (max-width: ${media.mobile}) {
			left: -13px;
		}
	}
`;
const SquadContainer = styled.div.withConfig({
	shouldForwardProp: (prop) => !["pickStatus", "isLong"].includes(prop),
})<{pickStatus: PickStatusEnum; isLong: boolean}>`
	background: ${(props) => SQUAD_COLORS[props.pickStatus].background};
	border: 2px solid ${(props) => SQUAD_COLORS[props.pickStatus].border};
	border-radius: 4px;
	flex-basis: 48%;
	position: relative;
	color: #333;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	${SquadLogo} {
		width: ${(props) => (props.isLong ? "29px" : "70%")};
		@media (max-width: ${media.mobile}) {
			width: ${(props) => (props.isLong ? "65px" : "70%")};
		}
	}
	${Seed} {
		background: ${(props) => SQUAD_COLORS[props.pickStatus].border};
		color: ${(props) => (props.pickStatus !== PickStatusEnum.Default ? "#fff" : "#636363")};
	}
	${PotentialPoints} {
		background: ${(props) => SQUAD_COLORS[props.pickStatus].border};
		height: ${(props) => (props.isLong ? "15px" : "20px")};
	}
`;
const BracketContent = styled.div`
	padding-bottom: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
`;
const EmptyImg = styled.img`
	opacity: 0.2;
`;
const SquadName = styled.div`
	font-size: 12px;
	font-family: ${theme.base_bold_font};
	text-align: center;
	white-space: nowrap;
`;
const Points = styled.div`
	font-size: 12px;
	font-family: ${theme.secondary_bold_font};
	font-weight: bolder;
	margin-top: 3px;
`;

const getIsLocked = (status: MatchStatusEnum) => status !== MatchStatusEnum.Scheduled;
const getIsShowResults = (status: MatchStatusEnum) => status !== MatchStatusEnum.Scheduled;
const getIsPicked = (pickSquadID: string | null, bracketSquadID: string | null) => pickSquadID === bracketSquadID;
const getSquadPoints = (match: IMatch | undefined, squadID: string) => {
	const matchHomeSquadID = get(match, "home_squad_id", "");
	const squadSide = squadID === matchHomeSquadID ? "home_game_points" : "away_game_points";
	return get(match, squadSide, 0);
};

interface IProps {
	squad?: ISquad;
	opponentSquad?: ISquad;
	emptyIcon: string;
	roundID: number;
	bracketID: number;
	conference: TConference;
	prediction: IPrediction;
	exceptionPrediction: IPrediction | undefined;
	prevIDs?: number[];
	match: IMatch | undefined;
	isLong?: boolean;
}

const PickLabels: IDictionary<string> = {
	failed: "0PTS",
	"re-pick": "Re-pick",
};

const getTeamName = (teamName: string) => last(teamName.split(" "));

const checkOnPick = (squadID: string, isLocked: boolean, isShared: boolean, isOpponentSquad: boolean) =>
	!squadID || isLocked || isShared || !isOpponentSquad;

// eslint-disable-next-line complexity
export const BracketSquad: React.FC<IProps> = ({
	squad,
	opponentSquad,
	emptyIcon,
	roundID,
	bracketID,
	conference,
	prediction = DEFAULT_PREDICTION,
	exceptionPrediction,
	prevIDs,
	match,
	isLong,
	// eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
	const dispatch = useDispatch();
	const squadID = get(squad, "id", "");
	const predictionSquadID = exceptionPrediction?.squad_id || null;
	let isPicked = getIsPicked(predictionSquadID, squadID);
	const bracketPrediction = isPicked ? exceptionPrediction : prediction;
	const {squad_id, status, max_points} = bracketPrediction || DEFAULT_PREDICTION;
	isPicked = getIsPicked(squad_id, squadID);
	const squadActive = get(squad, "active", 0);
	const seed = get(squad, "seed", 0);
	const squadConference = get(squad, "conference", conference);
	const shortName = get(squad, "short_name", "");
	const squadsLogoLink = import.meta.env.VITE_LOGO || "";
	const squadLogo = `${squadsLogoLink}/${squadID}.png`;
	const {pathname} = useLocation();

	const pickStatus = isPicked ? status : PickStatusEnum.Default;
	const isShowMaxPoints = [PickStatusEnum.Picked, PickStatusEnum.Correct].includes(status);
	const matchStatus = get(match, "status", MatchStatusEnum.Scheduled);
	const isLocked = getIsLocked(matchStatus);
	const isRePick = status === PickStatusEnum.RePick;
	const squadPoints = getSquadPoints(match, squadID);
	const isShowMatchResults = getIsShowResults(matchStatus);

	const predictionResult = useSelector(getBracketPredictionResultByIDSelector)(bracketID);

	const actualMaxPoints = predictionResult?.squad_id === squad?.id ? predictionResult?.max_points : max_points;

	const {multiply, maxPoints} = useMaxPoints(roundID, actualMaxPoints);

	const squadName = !isLong ? squad?.short_name : getTeamName(squad?.full_name || "");

	const isShared = pathname.split("/").includes("view");

	const makePickHandler = () => {
		if (checkOnPick(squadID, isLocked, isShared, Boolean(opponentSquad))) {
			return;
		}

		const pickData = {
			round: roundID,
			squad_id: squadID,
			bracketID,
			seed,
			conference: squadConference,
			squadName: shortName,
			prevBracketsIDS: prevIDs,
			opponent_squad_id: opponentSquad?.id,
		};
		if (exceptionPrediction) {
			dispatch(rePickAction());
			return;
		}
		if (isRePick) {
			dispatch(rePickAction());
			return;
		}
		if (squadActive) {
			dispatch(makePickAction(pickData));
		}

		const newBracketId = ++bracketID;
		if (newBracketId <= 13) {
			dispatch(setSelectedBracketIDAction(newBracketId));
		}
	};

	return (
		<SquadContainer onClick={makePickHandler} pickStatus={pickStatus} isLong={Boolean(isLong)}>
			<Exist when={!squad}>
				<EmptyImg src={emptyIcon} alt="Conference Squad" />
			</Exist>
			<Exist when={Boolean(squad)}>
				<React.Fragment>
					<BracketContent>
						<SquadLogo
							src={squadLogo}
							alt={"Squad logo"}
							className={squad?.id === "10402100-447f-396e-8149-0a434ffb2f23" ? "titans" : ""}
						/>
						<SquadName translate="no">{squadName}</SquadName>
						<Exist when={isShowMatchResults}>
							<Points>{squadPoints}</Points>
						</Exist>
						<Seed>{seed}</Seed>
					</BracketContent>
					<Exist when={isPicked}>
						<PotentialPoints>
							{isShowMaxPoints ? `${multiply}x=${maxPoints}PTS` : PickLabels[status]}
						</PotentialPoints>
					</Exist>
				</React.Fragment>
			</Exist>
		</SquadContainer>
	);
};
