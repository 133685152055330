export const SQUAD_LOGO_URL = import.meta.env.VITE_LOGO || "";
export const CONFIG_COUNTRY = import.meta.env.VITE_COUNTRY || "";
export const SYNDICATE_COUNTRY = import.meta.env.VITE_SYNDICATE_COUNTRY || "";
export const IS_UAT = import.meta.env.VITE_ENVIRONMENT === "uat";
export const SPONSOR_ENABLED = import.meta.env.VITE_SPONSOR === "true";

export const IS_PRE_SEASON = import.meta.env.VITE_IS_PRE_SEASON === "true";
export const IS_OFF_SEASON = import.meta.env.VITE_IS_OFF_SEASON === "true";
export const IS_OPEN_PRE_SEASON_REGISTRATION = true;

export const IS_SECRET_ENV = import.meta.env.VITE_IS_ON_SECRET === "true";

export const IS_API_ON_THE_SAME_HOST = (import.meta.env.VITE_API_URL || "").includes(window.location.host);

export const IS_HIDDEN_PRIZES = ["null"].includes(CONFIG_COUNTRY);
