import {find, get, isUndefined} from "lodash";
import {getTranslationStore} from "modules/selectors";
import {IDynamicParams} from "modules/types";
import {useEffect, useRef, useState, useCallback} from "react";
import {useSelector} from "react-redux";
import {TranslationArguments} from "modules/types/window";
import {GameSettingsUtils} from "modules/utils/secret";

const translate = (translations: Window["translations"], ...args: TranslationArguments) => {
	const [key, args1, args2 = []] = args;

	const isUAT = import.meta.env.VITE_ENVIRONMENT === "uat";
	const defaultValue = typeof args1 === "string" ? args1 : isUAT ? key : "";
	const dynamic_params = typeof args1 === "object" ? args1 : args2;
	const translation = get(translations, key, defaultValue);

	if (!dynamic_params) {
		return translation || "";
	}
	// TODO: Fix linter
	// @ts-ignore
	return translation.replace(/{{(\w+)}}/g, (_: any, k: string) => {
		return get(
			find(dynamic_params, (param) => !isUndefined(param[k])),
			k
		);
	});
};
export const useI18n = (key: string, dynamic_params?: IDynamicParams[]) => {
	const translations = useSelector(getTranslationStore);
	return translate(translations, key, dynamic_params);
};

window.getTranslations = (...args: TranslationArguments) => {
	return translate(window.translations, ...args);
};

export const useMediaQuery = (query: string) => {
	const mediaMatch = window.matchMedia(query);
	const [matches, setMatches] = useState(mediaMatch.matches);

	useEffect(() => {
		const handler = (e: MediaQueryListEvent) => setMatches(e.matches);
		if ("addListener" in mediaMatch) {
			mediaMatch.addListener(handler);
		} else {
			// @ts-ignore
			mediaMatch.addEventListener("change", handler);
		}

		return () => {
			if ("addListener" in mediaMatch) {
				mediaMatch.removeListener(handler);
			} else {
				// @ts-ignore
				mediaMatch.removeEventListener("change", handler);
			}
		};
	});

	return matches;
};

export const usePrevious = <T = undefined>(value: T): T | undefined => {
	const ref = useRef<T>();

	useEffect(() => {
		ref.current = value;
	});

	return ref.current;
};

export const useSecretEnv = (redirect: string = "/") => {
	const [isUnderSecret, setSecretState] = useState(GameSettingsUtils.ENABLED);
	const [count, setCount] = useState(0);

	const onSecretClick = useCallback(() => {
		if (isUnderSecret) {
			setCount(count + 1);
			if (count >= 10) {
				GameSettingsUtils.PASS();
				setSecretState(false);

				if (redirect) {
					window.location.replace("/game");
				}
			}
		}
	}, [isUnderSecret, count, redirect]);

	return {isUnderSecret, onSecretClick};
};
