import React from "react";
import styled, {css} from "styled-components";
import ClearIcon from "assets/img/icons/clear-grey.svg";
import passwordIcon from "assets/img/icons/password_icon.png";
import lock_icon from "assets/img/icons/Lock.png";

interface IInput {
	readonly is_error?: boolean;
	readonly is_disabled?: boolean;
}

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
`;

export const InputStyle = styled.input.withConfig({
	shouldForwardProp: (prop) => !["is_error", "is_disabled"].includes(prop),
})<IInput>`
	height: 40px;
	width: 100%;
	border: 1px solid ${({is_error}: IInput) => (is_error ? "red" : "#CCCCCC")};
	color: ${({is_error}: IInput) => (is_error ? "red" : "#757575")};
	border-radius: 4px;
	box-sizing: border-box;
	padding: 0 10px;
	margin-top: 5px;

	&:focus {
		border-color: #1b48e0;
		color: #333333;
	}

	&[type="password"] {
		background: url("${passwordIcon}") no-repeat right 10px center;
	}

	${({is_disabled}) =>
		is_disabled &&
		css`
			background: url("${lock_icon}") no-repeat right 10px center;
			pointer-events: none;
		`};
`;

const ClearElement = styled.div`
	position: absolute;
	//right: 0;
	//top: 19px;
	top: 14px;
	right: 10px;
	border: none;
	height: 24px;
	width: 24px;
	cursor: pointer;
	background: url("${ClearIcon}") no-repeat;
`;

const defaultOnChange = () => null;

interface IProps {
	name: string;
	type: string;
	value?: string;
	placeholder?: string;
	className?: string;
	is_disabled?: boolean;
	maxLength?: number;
	minLength?: number;
	is_error?: boolean;
	clearField?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: number) => void | undefined;
	onChange?: any;
	required?: boolean;
	onBlur?: (e: React.BaseSyntheticEvent) => void;
	title?: string;
}

export const Input = ({
	name,
	type,
	value,
	placeholder,
	is_disabled,
	className,
	maxLength,
	minLength,
	is_error,
	clearField,
	onChange,
	required,
	...rest
}: IProps) => (
	<InputWrapper className={className}>
		<InputStyle
			name={name}
			type={type}
			value={value}
			placeholder={placeholder}
			is_disabled={is_disabled}
			is_error={is_error}
			maxLength={maxLength}
			minLength={minLength}
			onChange={onChange || defaultOnChange}
			required={required}
			{...rest}
		/>
		{value && value !== "" && type === "text" && !is_disabled ? (
			<ClearElement data-element_name={name} onClick={clearField} />
		) : null}
	</InputWrapper>
);
