import React from "react";
import styled, {css} from "styled-components";
import {ITeamStatistics} from "modules/types";
import {get, toPairs, size} from "lodash";
import {useSelector} from "react-redux";
import {getSquads} from "modules/selectors";
import theme from "assets/css/theme";

const StatsLine = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 50%;
	margin: 0 auto;
`;

const RankStatsLine = styled(StatsLine)`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	width: auto;
	padding: 0 10px;
`;

const LINE_WIDTH_STEP = 6;

const RankLine = styled.div<{line_width: number}>`
	border-radius: 3px;
	min-width: 10px;
	max-width: 50px;
	height: 6px;
	background-color: #cad2d8;
	margin: 0 10px;
	width: ${({line_width}) => `${line_width * LINE_WIDTH_STEP}px`};
	@media screen and (max-width: 840px) {
		max-width: 50%;
	}
`;

const RankLabel = styled.div`
	color: #ffffff;
	font-size: 10px;
	font-weight: bold;
	letter-spacing: 0.08px;
	line-height: 11px;
	text-align: center;
	width: 33px;
	height: 33px;
	border-radius: 50%;
	background-color: #cad2d8;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: ${theme.secondary_bold_font};
`;

interface IIsWin {
	is_win: boolean;
}

const AwayRank = styled.div<IIsWin>`
	display: flex;
	align-items: center;
	width: 170px;
	justify-content: flex-end;
	@media screen and (max-width: 840px) {
		width: 40%;
	}
	${({is_win}) =>
		is_win
			? css`
					${RankLabel}, ${RankLine} {
						background-color: #000000;
					}
				`
			: ""};
`;

const HomeRank = styled.div<IIsWin>`
	display: flex;
	align-items: center;
	width: 170px;
	@media screen and (max-width: 840px) {
		width: 40%;
	}
	${({is_win}) =>
		is_win
			? css`
					${RankLabel}, ${RankLine} {
						background-color: black;
					}
				`
			: ""};
`;

const RankTitle = styled.div`
	height: 10px;
	width: 60px;
	color: #151515;
	font-size: 10px;
	font-weight: 500;
	line-height: 10px;
	text-align: center;
	text-transform: uppercase;
`;

interface IProps {
	statistics: ITeamStatistics;
}

export const LeagueRankings: React.FC<IProps> = ({statistics}) => {
	const squads = useSelector(getSquads);
	const getRankLine = (arr: string[]) => {
		if (!statistics) {
			return null;
		}
		const [key, title] = arr;
		const max_place = size(squads);
		const {home_squad_id, away_squad_id, league_ranks} = statistics;

		const home_label = get(league_ranks, `${home_squad_id}.${key}`, "");
		const away_label = get(league_ranks, `${away_squad_id}.${key}`, "");

		const home_score = parseInt(home_label as string, 10);
		const away_score = parseInt(away_label as string, 10);

		const is_home_winner = home_score < away_score;
		const is_away_winner = away_score < home_score;

		const home_width = max_place / (home_score || max_place);
		const away_width = max_place / (away_score || max_place);

		return (
			<RankStatsLine key={key}>
				<AwayRank is_win={is_home_winner}>
					<RankLabel>{Number.isNaN(home_score) ? 0 : home_score}</RankLabel>
					<RankLine line_width={home_width} />
				</AwayRank>
				<RankTitle>{title}</RankTitle>
				<HomeRank is_win={is_away_winner}>
					<RankLine line_width={away_width} />
					<RankLabel>{Number.isNaN(away_score) ? 0 : away_score}</RankLabel>
				</HomeRank>
			</RankStatsLine>
		);
	};

	const rank_keys = {
		offense_yards_per_game_rank: window.getTranslations("pickem_formguide_dropdown_offence") || "",
		defense_yards_per_game_rank: window.getTranslations("pickem_formguide_dropdown_defense") || "",
		offense_passing_yards_per_game_rank: window.getTranslations("pickem_formguide_dropdown_passing") || "",
		offense_rushing_yards_per_game_rank: window.getTranslations("pickem_formguide_dropdown_rushing") || "",
	};

	return <React.Fragment>{toPairs(rank_keys).map(getRankLine)}</React.Fragment>;
};
