import React, {useEffect, PropsWithChildren} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getGlobalStore} from "modules/selectors";
import {fetchCountryAction} from "modules/actions";
import {IDictionary} from "modules/types";
// import styled from "styled-components";

// const Wrapper = styled.div`
// 	flex: 1;
// 	display: flex;
// 	flex-direction: column;
// `;

const REDIRECT_COUNTRIES = [
	"AR",
	"BO",
	"CL",
	"CO",
	"CR",
	"DO",
	"EC",
	"SV",
	"GT",
	"HN",
	"JM",
	"NI",
	"PA",
	"PY",
	"PE",
	"UY",
	"VE",
];
const redirectLinks: IDictionary<string> = {
	uat: "//uat-nfl-predictor-mx.fanhubmedia.com",
	preprod: "//preprod-nfl-predictor-mx.fanhubmedia.com",
	production: "//superbowlchallenge.es",
};
export const CountriesNavigate: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const {country} = useSelector(getGlobalStore);
	useEffect(() => {
		dispatch(fetchCountryAction());
	}, [dispatch]);
	if (country?.length && REDIRECT_COUNTRIES.indexOf(country) > -1) {
		const redirectLink = import.meta.env.VITE_ENVIRONMENT || "prod";
		window.location.href = redirectLinks[redirectLink];
		return null;
	}
	return country ? <React.Fragment>{children}</React.Fragment> : null;
};
