import React, {PropsWithChildren} from "react";
import styled from "styled-components";
import {Container} from "components/Container";
import logo from "assets/img/logo-long.svg";
import deLogo from "assets/img/de-logo-long.svg";
import {useSelector} from "react-redux";
import {isAuthedSelector} from "modules/selectors";
import {IMenuItem} from "modules/types";
import {NavLink} from "react-router-dom";
import iconUser from "assets/img/icons/Avatar.svg";
import {media} from "assets/css/media";
import {DesktopMenu} from "components/Navigation";
import {MobileMenu} from "components/Navigation/MobileMenu";
import {useI18n} from "modules/hooks";
import {LeaguesDesktopMenu} from "components/Navigation/LeaguesDesktopMenu";
import {Exist} from "components/Exist";
import {GameSettingsUtils} from "modules/utils/secret";
import {SPONSOR_ENABLED} from "modules/constants";

const HeaderWrapper = styled.header.withConfig({
	shouldForwardProp: (prop) => !["bgColor"].includes(prop),
})<{bgColor?: string}>`
	background: ${(props) => (props.bgColor ? props.bgColor : "#101251")};
	height: 62px;
	padding: 10px;
	position: relative;
	z-index: 21;
	@media (max-width: ${media.mobile}) {
		padding: 0 0 0 10px;
	}
`;
const HeaderContainer = styled(Container)`
	align-items: center;
	flex-wrap: nowrap;
`;
const Logo = styled.img`
	height: 100%;
	@media (max-width: ${media.mobile}) {
		padding: 10px 0;
	}
`;
const PresentedBy = styled.div`
	margin-left: auto;
	color: #ffffff;
	font-size: 12px;
	display: flex;
	align-items: center;
	height: 100%;
	padding-right: 10px;
	span {
		@media (max-width: ${media.mobile}) {
			display: none;
		}
	}
`;
const AccountLink = styled(NavLink)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 25px;
	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;
const IconUser = styled.img``;
const LogoLink = styled(NavLink)`
	height: 100%;
`;

const SponsorText = styled.span`
	padding-right: 4px;
`;
interface IProps {
	menuItems: IMenuItem[];
	SyndicateLogo?: React.FC;
	SponsorLogo?: React.FC;
	bgColor?: string;
	sponsorLink?: string;
	hiddenSponsor?: boolean;
	hiddenSponsorText?: boolean;
}

const LinkWrapper: React.FC<PropsWithChildren<{link?: string}>> = ({link, children}) => {
	if (link) {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	}

	return <>{children}</>;
};

export const Header: React.FC<IProps> = ({
	menuItems,
	SyndicateLogo,
	SponsorLogo,
	bgColor,
	sponsorLink,
	hiddenSponsor = false,
	hiddenSponsorText = false,
}) => {
	const isAuthed = useSelector(isAuthedSelector);
	const isActiveSeason = GameSettingsUtils.IS_ENABLED_SESSION;
	const presentedText = useI18n("register_text_text_ad");
	const headerLogo = import.meta.env.VITE_NAME === "nfl-de" ? deLogo : logo;

	return (
		<React.Fragment>
			<HeaderWrapper bgColor={bgColor}>
				<HeaderContainer>
					<LogoLink to={GameSettingsUtils.ROOT_GAME_ROUTE}>
						<Logo src={headerLogo} alt={"Brackets Logo"} />
					</LogoLink>

					{!!SyndicateLogo && <SyndicateLogo />}

					<Exist when={isActiveSeason}>
						<DesktopMenu menuItems={menuItems} />
					</Exist>

					<Exist when={SPONSOR_ENABLED}>
						<LinkWrapper link={sponsorLink}>
							<PresentedBy>
								<Exist when={!hiddenSponsor}>
									<Exist when={!hiddenSponsorText}>
										<SponsorText>{presentedText}</SponsorText>
									</Exist>

									{!!SponsorLogo && <SponsorLogo />}
								</Exist>
							</PresentedBy>
						</LinkWrapper>
					</Exist>

					{isAuthed && (
						<AccountLink to="/account/update">
							<IconUser src={iconUser} alt="User Icon" />
						</AccountLink>
					)}

					<Exist when={isActiveSeason}>
						<MobileMenu menuItems={menuItems} />
					</Exist>
				</HeaderContainer>
			</HeaderWrapper>
			<LeaguesDesktopMenu menuItems={menuItems} />
		</React.Fragment>
	);
};
