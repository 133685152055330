import {IStore, PickStatusEnum} from "modules/types";
import {createSelector} from "reselect";
import {pickBy, keys, mapValues, find, values, filter, get, omit} from "lodash";
import {getRoundsMatchesSelector} from "modules/selectors/rounds";
import {BRACKETS_CONFIG} from "modules/constants";

export const getPredictionsStore = ({predictions}: IStore) => predictions;
export const getPredictionsSelector = createSelector(getPredictionsStore, (predictionStore) =>
	omit(predictionStore, "undefined", "gameBar")
);
export const getBracketPredictionByIDSelector = createSelector(
	getPredictionsStore,
	(predictions) => (bracketID: number) => predictions[bracketID]
);
export const getBracketExceptionPredictionSelector = createSelector(
	getPredictionsStore,
	(predictions) => (bracketID: number) => find(predictions, {real_bracket_id: bracketID})
);

export const getHasRePick = createSelector(getPredictionsStore, (predictions) =>
	values(predictions).some((prediction) => prediction.status === PickStatusEnum.RePick)
);

export const getGameBarDataSelector = createSelector(
	[getPredictionsStore, getRoundsMatchesSelector],
	(predictions, matches) => {
		const {size} = BRACKETS_CONFIG;
		const predictionsOnly = omit(predictions, "undefined", "gameBar");
		const predictionsPicked = pickBy(predictionsOnly, (prediction) => prediction?.squad_id);
		const predictionsValidPicked = pickBy(
			predictionsOnly,
			(prediction) => prediction.status === PickStatusEnum.Picked
		);
		const actualMatches = filter(matches, (match) => ["active", "complete"].indexOf(match.status) > -1).length;
		const isGameStarted = find(matches, ({status}) => ["playing", "complete"].includes(status));
		let points = 0;
		mapValues(predictionsOnly, (prediction) => {
			points += prediction.points ? prediction.points : 0;
		});
		const predictionsSize = keys(predictionsPicked).length;
		const validSize = keys(predictionsValidPicked).length;
		const isCanSave = size - actualMatches === validSize;
		return {
			predictionsSize: predictionsSize,
			points,
			isGameStarted,
			isCanSave,
			ranking: get(predictions, "gameBar.overall_rank", "-") as unknown as string,
		};
	}
);
