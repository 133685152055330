import theme from "assets/css/theme";
import styled from "styled-components";

export const PageFooter = styled.footer`
	width: 100%;
	display: flex;
	> div {
		flex: 1;
	}
`;

export const MobilePageFooter = styled.footer`
	display: block;
	width: 100%;
`;

export const CustomLabel = styled.label`
	color: #757575;
	display: block;
	font-size: 12px;
	padding-bottom: 5px;
	line-height: 20px;
	text-transform: capitalize;

	a {
		text-decoration: underline;
	}

	font-family: ${theme.base_font};
`;

export const TableHead = styled.div`
	display: flex;
	align-items: center;
	background: #fafafa;
	height: 40px;
	border-bottom: 1px solid #eee;
	> div {
		padding: 0 10px;
		box-sizing: border-box;
		//flex: 1;
	}
	color: #333333;
	font-family: ${theme.base_font};
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
`;

export const LadderWrapper = styled.div`
	border-radius: 4px;
	overflow: hidden;
`;

export const TeamName = styled.span`
	font-family: ${theme.menu_font};
	color: #505050;
`;

export const TableBody = styled.div`
	margin-bottom: 20px;
`;

interface ITableBodyRow {
	is_active?: boolean;
}

export const TableBodyRow = styled.div<ITableBodyRow>`
	display: flex;
	font-family: ${theme.base_font};
	align-items: center;
	border-bottom: 1px solid ${({is_active}: ITableBodyRow) => (is_active ? "#2248e0" : "#eee")};
	background: ${({is_active}: ITableBodyRow) => (is_active ? "#e4f4fd" : "#fff")};
	height: 40px;
	font-size: 12px;
	line-height: 14px;
	&:nth-child(even) {
		background: ${({is_active}: ITableBodyRow) => (is_active ? "#e4f4fd" : "#fafafa")};
	}
	> div {
		padding: 0 10px;
		//flex: 1;/
		box-sizing: border-box;
	}
`;

export const LeagueName = styled.div`
	background: #000;
	color: #fff;
	padding: 0 20px;
	font-family: ${theme.base_font};
	box-sizing: border-box;
	height: 40px;
	font-size: 12px;
	font-weight: 500;
	line-height: 14px;
	display: flex;
	align-items: center;
`;

export const TableHeadCell = styled.div<{
	value_align?: string;
	is_active: boolean;
}>`
	cursor: pointer;
	color: ${({is_active}) => (is_active ? "#1B48E0" : "#505050")};
	width: 100%;
	max-width: 150px;
	flex: 1 1 100%;
	font-family: ${theme.menu_font};
	font-size: 12px;
	white-space: nowrap;
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.value_align || "flex-start"};
	svg {
		display: ${({is_active}) => (is_active ? "inline" : "none")};
		color: #1b48e0;
		min-width: 10px;
		width: 10px;
		margin: 0 5px;
	}
	&.team_name {
		max-width: none;
		flex: 1 1 auto;
	}

	&.position {
		max-width: 90px;
	}

	@media screen and (max-width: 480px) {
		max-width: 60px;
		text-align: center;
		&.team_name {
			min-width: 150px;
		}

		&.position {
			white-space: nowrap;
			max-width: 80px;
		}
		span {
			white-space: normal;
		}
	}
`;

export const TableBodyCell = styled.div<{
	value_align?: string;
}>`
	width: 100%;
	max-width: 150px;
	font-weight: 500;
	display: flex;
	align-items: center;
	justify-content: ${(props) => props.value_align || "flex-start"};

	strong {
		margin-right: 5px;
	}

	span {
		color: #757575;
		font-weight: normal;
	}

	&.team_name {
		max-width: none;
		flex: 1 1 auto;
	}

	&.position {
		max-width: 90px;
	}

	@media screen and (max-width: 480px) {
		flex-wrap: wrap;
		max-width: 60px;
		white-space: normal;
		text-align: center;
		&.team_name {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			min-width: 150px;
		}

		&.position {
			white-space: nowrap;
			max-width: 80px;
		}
	}
`;

export const FiltersWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	padding: 0 10px;
	@media screen and (max-width: 840px) {
		margin: 0 20px 20px;
		padding: 0;
		flex-direction: column;
		& > div {
			width: 100%;
			input,
			select {
				width: 100%;
			}
		}
	}
	select {
		margin-top: 0;
		width: 160px;
		margin-right: 10px;
		@media screen and (max-width: 840px) {
			width: 100%;
		}
	}
`;

export const FiltersRight = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media screen and (max-width: 840px) {
		margin: 0;
	}
	select {
		margin-top: 0;
		width: 160px;
		margin-right: 10px;
		@media screen and (max-width: 840px) {
			width: 100%;
		}
	}
	input {
		width: 300px;
		margin-bottom: 0;
		@media screen and (max-width: 840px) {
			width: 100%;
		}
	}

	& > div {
		width: 100%;
	}
`;
