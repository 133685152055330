import {Api, ApiError} from "modules/utils/Api";
import {call, put, take} from "redux-saga/effects";
import {IEmail, ILeaveLeague, ISagaAction} from "modules/types";
import {SagaIterator} from "redux-saga";
import {assign, get, gt, size} from "lodash";
import {
	errorsGlobalError,
	inviteViaEmailFailed,
	inviteViaEmailSuccess,
	joinToLeagueFailed,
	joinToLeagueSuccess,
	leaveLeagueFailed,
	leaveLeagueSuccess,
	showLeagueFailed,
	showLeaguesForJoinFailed,
	showLeaguesForJoinMore,
	showLeaguesForJoinSuccess,
	showLeagueSuccess,
	showMyLeaguesFailed,
	showMyLeaguesSuccess,
} from "modules/actions";
import {createConnextraScriptTag} from "modules/utils";
import {User} from "modules/utils/User";

const GAME_NAME = import.meta.env.VITE_NAME || "";

export interface IActionSearch {
	value?: string;
}

export interface ILoadMore {
	readonly offset?: number | undefined;
	readonly query?: string | undefined;
}

export const showMyLeaguesForJoinSaga = function* (action: ISagaAction<IActionSearch & ILoadMore>): SagaIterator {
	try {
		const LIMIT = 10;
		const ZERO_OFFSET = 0;
		const query = get(action, "payload.value");
		const load_more_query = get(action, "query", "");
		const load_more_offset: number = get(action, "offset", ZERO_OFFSET);

		const {result, errors} = yield call(Api.Joins.show_for_join, {
			offset: load_more_offset,
			q: query,
		});

		ApiError.CHECK(errors);

		yield put(
			showLeaguesForJoinSuccess({
				items: result || [],
				next: gt(size(result), 0),
			})
		);

		const new_offset = query === load_more_query || !query ? load_more_offset + LIMIT : ZERO_OFFSET;

		assign(action, {
			offset: new_offset || LIMIT,
			query,
		});

		yield take(showLeaguesForJoinMore);
		yield call(showMyLeaguesForJoinSaga, action);
	} catch (e) {
		yield put(showLeaguesForJoinFailed());
		yield put(errorsGlobalError(e));
	}
};

export const showMyLeaguesSaga = function* (): SagaIterator {
	try {
		const LIMIT = 15;
		const {result, errors} = yield call(Api.League.show_my, {
			// offset: action.payload.offset,
			limit: LIMIT,
		});

		ApiError.CHECK({errors});

		yield put(
			showMyLeaguesSuccess({
				next: result.length,
				items: result,
			})
		);
	} catch (e) {
		console.error(e);
		yield put(showMyLeaguesFailed());
		yield put(errorsGlobalError(e));
	}
};

export const joinToLeagueSaga = function* (action: ISagaAction<string>): SagaIterator {
	const code: string = action.payload;
	try {
		const {errors} = yield call(Api.Joins.join, {code});

		ApiError.CHECK({errors});

		const user = User.GET();
		createConnextraScriptTag(
			`https://us.connextra.com/dcs/tagController/tag/11935e1e2233/f2p_superbowlchallenge_leaguejoin?AccountID=${user.id}`
		);

		yield put(joinToLeagueSuccess(code));
	} catch (e) {
		yield put(joinToLeagueFailed(code));
		yield put(errorsGlobalError(e));
	}
};

export const postLeagueLeaveSaga = function* (action: ISagaAction<ILeaveLeague>): SagaIterator {
	try {
		const {errors} = yield call(Api.Joins.leave, {
			league_id: action.payload.league_id,
		});
		ApiError.CHECK({errors});

		yield put(leaveLeagueSuccess(action.payload));
	} catch (e) {
		yield put(leaveLeagueFailed());
		yield put(errorsGlobalError(e));
	}
};

export const getLeagueByIdSaga = function* (action: ISagaAction<number>): SagaIterator {
	try {
		const {result, errors} = yield call(Api.League.show, {
			id: action.payload,
		});

		ApiError.CHECK({errors});

		yield put(showLeagueSuccess(result));
	} catch (e) {
		yield put(showLeagueFailed());
		yield put(errorsGlobalError(e));
	}
};

export interface IEmailInvites {
	readonly league_id: number;
	readonly invites: IEmail[];
}

export const postInvitesSendViaEmail = function* (action: ISagaAction<IEmailInvites>): SagaIterator {
	try {
		const {errors} = yield call(Api.Joins.invite, action.payload);

		ApiError.CHECK({errors});

		if (GAME_NAME === "nfl-gb" && window.fbq) {
			window.fbq("track", "Lead");
		}

		yield put(inviteViaEmailSuccess());
	} catch (e) {
		yield put(inviteViaEmailFailed());
		yield put(errorsGlobalError(e));
	}
};

export const searchAndJoinSaga = function* (action: ISagaAction<string>): SagaIterator {
	try {
		const query = get(action, "payload");
		console.log("--=--query", query);
		const {result, errors} = yield call(Api.Joins.show_for_join, {
			q: query,
		});

		ApiError.CHECK({errors});

		yield put(
			showLeaguesForJoinSuccess({
				items: result || [],
				next: gt(size(result), 0),
			})
		);

		yield call(joinToLeagueSaga, action);
	} catch (e) {
		yield put(showLeaguesForJoinFailed());
		yield put(errorsGlobalError(e));
	}
};
