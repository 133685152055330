import styled from "styled-components";
import header_bg_mobile from "assets/img/header_bg_mobile.png";
import header_bg from "assets/img/header_bg.png";

interface IContentHeader {
	header_background?: string;
	header_background_mobile?: string;
}

export const ContentHeader = styled.div<IContentHeader>`
	height: 140px;
	background: ${({header_background}) => (header_background ? `url(${header_background})` : `url(${header_bg})`)}
		no-repeat;
	background-size: 100% 100%;
	color: #ffffff;
	font-size: 60px;
	letter-spacing: -0.2px;
	text-transform: uppercase;
	font-family: "AllProDisaplayB-Medium";
	position: relative;
	> span {
		position: absolute;
		left: 80px;
		bottom: 25px;
	}

	@media screen and (max-width: 820px) {
		background-image: ${({header_background_mobile}: IContentHeader) =>
			header_background_mobile ? `url(${header_background_mobile})` : `url(${header_bg_mobile})`};
		background-size: cover;
		height: 100px;
		font-size: 24px;

		> span {
			left: 30px;
			bottom: 20px;
		}
	}
`;
