import React from "react";
import {Outlet, Navigate, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {isAuthedSelector} from "modules/selectors";

export const PrivateRoute: React.FC = () => {
	const isAuthed = useSelector(isAuthedSelector);
	const location = useLocation();
	return isAuthed ? (
		<Outlet />
	) : (
		<Navigate
			to={{
				pathname: "/",
			}}
			state={{from: location}}
		/>
	);
};
