import {IStore} from "modules/types";
import {SYNDICATE_COUNTRY, SPONSOR_ENABLED} from "modules/constants";

export const getOddsType = ({odds}: IStore) => odds;

/**
 * Odds is available in this season
 */
export const isOddsVisible = () => (SYNDICATE_COUNTRY === "GB" || SYNDICATE_COUNTRY === "CA") && SPONSOR_ENABLED;
export const isOddsCalculationVisible = () => SYNDICATE_COUNTRY === "GB";
