import {IBracket, IDictionary, IMatch, MatchStatusEnum} from "modules/types";
import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getBracketMatchByBracketIDSelector, getBracketTeamsSelector} from "modules/selectors/brackets";
import {getBracketExceptionPredictionSelector, getBracketPredictionByIDSelector} from "modules/selectors/predictions";
import {getOddsType, isOddsVisible} from "modules/selectors";
import moment from "moment-timezone";
import {eq, get, defaultTo} from "lodash";
import {OddsType} from "modules/constants/enums";
import styled from "styled-components";
import {Odds} from "components/Odds";
import {Betting888Logo, BettingFandueLogo} from "components/SkyBetLogo";
import {Exist} from "components/Exist";
import afcLogo from "assets/img/afc-logo.svg";
import nfcLogo from "assets/img/nfc-logo.svg";
import {BracketSquad} from "components/BracketsPicker/BracketSquad";
import {TeamStatistics} from "components";
import theme from "assets/css/theme";
import {media} from "assets/css/media";
import {Broadcasters} from "components/BracketsPicker/Broadcasters";
import playIcon from "assets/img/play.png";
import {setHighlightVideoAction} from "modules/actions";
import {SYNDICATE_COUNTRY, SPONSOR_ENABLED} from "modules/constants";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const BracketItemWrapper = styled.div`
	background: #fff;
	width: 95%;
	min-width: 280px;
	box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
	margin-right: 20px;
	position: relative;
	border-radius: 4px;
	user-select: none;
	z-index: 3;
	&:last-child {
		margin-right: 0;
	}
	@media (max-width: ${media.mobile}) {
		max-width: 100%;
		width: 100%;
	}
`;

const BracketItemHeader = styled.div`
	background: #f9f9f9;
	padding: 5px 10px;
	font-size: 12px;
	color: #333;
	letter-spacing: 0.1px;
	border-radius: 4px 4px 0 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 30px;
`;

const BracketsPickWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 10px;
	height: 80px;
	position: relative;
	@media (max-width: ${media.mobile}) {
		height: 137px;
	}
`;

const BracketStatWrapper = styled.div`
	position: relative;
`;

const BracketOddsWrapper = styled.div`
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.05), #fff 50%);
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 0 0 4px 4px;
	@media (max-width: ${media.mobile}) {
		background: none;
		border-top: 1px solid #e0e0e0;
	}
`;
const RoundName = styled.div`
	height: 20px;
	font-size: 14px;
	margin-bottom: 10px;
	font-family: ${theme.semiBoldFont};
	text-transform: uppercase;

	@media (max-width: ${media.mobile}) {
		margin-left: 9px;
	}
`;

const HighLightsWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 30px;
	border-top: 1px solid #e0e0e0;
	font-family: ${theme.semiBoldFont};
	font-size: 14px;
	cursor: pointer;
	img {
		width: 18px;
		margin-left: 5px;
	}
`;

const roundDisplay: IDictionary<string> = {
	1: "global_round_wild_card",
	7: "global_round_divisional_round",
	11: "global_round_conference_championship",
	13: "global_round_super_bowl",
};

const getIsHightlight = (bracketMatch: IMatch, highlight: string) =>
	Boolean(bracketMatch?.status === MatchStatusEnum.Complete && highlight);

const oddsSource = import.meta.env.VITE_ODDS_SOURCE || "";

interface IBracketItemProps {
	bracket: IBracket;
}

// eslint-disable-next-line complexity
export const BracketItem: React.FC<IBracketItemProps> = ({bracket}) => {
	const dispatch = useDispatch();
	const teams = useSelector(getBracketTeamsSelector)(bracket);
	const homeSquad = teams.homeSquad!;
	const awaySquad = teams.awaySquad!;
	const {bracketID, conference, roundID} = bracket;
	const bracketMatch = useSelector(getBracketMatchByBracketIDSelector)(bracketID)!;

	const prediction = useSelector(getBracketPredictionByIDSelector)(bracketID);
	const exceptionPrediction = useSelector(getBracketExceptionPredictionSelector)(bracketID);
	const oddsType = useSelector(getOddsType);
	const isOddsAvailable = useSelector(isOddsVisible) && bracketMatch?.status !== MatchStatusEnum.Complete;

	const emptyIcon = conference === "afc" ? afcLogo : nfcLogo;
	const getEmptyLogo = (side: string) => (bracketID === 13 && side === "home" ? afcLogo : emptyIcon);

	const matchDate = useMemo(
		() =>
			bracketMatch?.date
				? moment(bracketMatch?.date).format("h:mmA, MMM Do")
				: window.getTranslations("bracket_card_date.tbd"),
		[bracketMatch]
	);

	const isH2HOdds = eq(oddsType, OddsType.HeadToHead);

	const isHomeSquadSelected = eq(prediction?.squad_id, isH2HOdds ? awaySquad?.id : homeSquad?.id);
	const isAwaySquadSelected = eq(prediction?.squad_id, isH2HOdds ? homeSquad?.id : awaySquad?.id);

	const homeSquadOddsID = isH2HOdds
		? get(bracketMatch, `home_squad_odds.${oddsSource}.id`, 0)
		: get(homeSquad, `superbowl_odds.${oddsSource}.id`, 0);
	const homeSquadOddsValue = isH2HOdds
		? get(bracketMatch, `home_squad_odds.${oddsSource}.value`, 0)
		: get(homeSquad, `superbowl_odds.${oddsSource}.value`, 0);

	const awaySquadOddsID = isH2HOdds
		? get(bracketMatch, `away_squad_odds_id.${oddsSource}.id`, 0)
		: get(awaySquad, `superbowl_odds.${oddsSource}.id`, 0);
	const awaySquadOddsValue = isH2HOdds
		? get(bracketMatch, `away_squad_odds.${oddsSource}.value`, 0)
		: get(awaySquad, `superbowl_odds.${oddsSource}.value`, 0);

	const roundName = roundDisplay[bracketID] ? roundDisplay[bracketID] : "";
	const replace = /AFC|NFC?/;
	const broadcasters = defaultTo(bracketMatch?.broadcasters, {});

	const highlight = get(bracketMatch, "video.highlights", "");

	const isShowHighlight = getIsHightlight(bracketMatch, highlight);

	const showHighlightHandler = () => {
		dispatch(setHighlightVideoAction(highlight));
	};

	return (
		<Wrapper id={`swipe_bracket_${bracket.bracketID}`}>
			<RoundName>{window.getTranslations(roundName).replace(replace, "")}</RoundName>
			<BracketItemWrapper>
				<BracketItemHeader>
					{matchDate}
					<Broadcasters broadcasters={broadcasters} />
				</BracketItemHeader>
				<BracketsPickWrapper>
					<BracketSquad
						squad={homeSquad}
						emptyIcon={getEmptyLogo("home")}
						roundID={roundID}
						bracketID={bracketID}
						conference={conference}
						prediction={prediction}
						exceptionPrediction={exceptionPrediction}
						prevIDs={bracket.prevID}
						match={bracketMatch}
						isLong={true}
						opponentSquad={awaySquad}
					/>
					<BracketSquad
						squad={awaySquad}
						emptyIcon={getEmptyLogo("away")}
						roundID={roundID}
						bracketID={bracketID}
						conference={conference}
						prediction={prediction}
						exceptionPrediction={exceptionPrediction}
						prevIDs={bracket.prevID}
						match={bracketMatch}
						isLong={true}
						opponentSquad={homeSquad}
					/>
				</BracketsPickWrapper>
				<Exist when={isShowHighlight}>
					<HighLightsWrapper onClick={showHighlightHandler}>
						{window.getTranslations("bracket_matchup_button_highlights")}
						<img src={playIcon} alt="play icon" />
					</HighLightsWrapper>
				</Exist>
				<Exist when={!isShowHighlight}>
					<TeamStatistics homeSquad={homeSquad} awaySquad={awaySquad} />
				</Exist>
				<BracketStatWrapper>
					<Exist when={isOddsAvailable}>
						<BracketOddsWrapper>
							{isH2HOdds ? (
								<React.Fragment>
									<Odds
										isSelected={isAwaySquadSelected}
										oddsID={awaySquadOddsID}
										oddsValue={awaySquadOddsValue}
									/>
									<Exist when={SPONSOR_ENABLED}>
										<Exist when={SYNDICATE_COUNTRY === "GB"}>
											<Betting888Logo />
										</Exist>
										<Exist when={SYNDICATE_COUNTRY === "CA"}>
											<BettingFandueLogo />
										</Exist>
									</Exist>

									<Odds
										isSelected={isHomeSquadSelected}
										oddsID={homeSquadOddsID}
										oddsValue={homeSquadOddsValue}
									/>
								</React.Fragment>
							) : (
								<React.Fragment>
									<Odds
										isSelected={isHomeSquadSelected}
										oddsID={homeSquadOddsID}
										oddsValue={homeSquadOddsValue}
									/>
									<Exist when={SPONSOR_ENABLED}>
										<Exist when={SYNDICATE_COUNTRY === "GB"}>
											<Betting888Logo />
										</Exist>
										<Exist when={SYNDICATE_COUNTRY === "CA"}>
											<BettingFandueLogo />
										</Exist>
									</Exist>
									<Odds
										isSelected={isAwaySquadSelected}
										oddsID={awaySquadOddsID}
										oddsValue={awaySquadOddsValue}
									/>
								</React.Fragment>
							)}
						</BracketOddsWrapper>
					</Exist>
				</BracketStatWrapper>
			</BracketItemWrapper>
		</Wrapper>
	);
};
