import * as React from "react";
import {
	FooterWrapper,
	FooterMenuWrapper,
	TermLink,
	Copywriting,
	FooterImgDiv,
	FooterImg,
	FooterImgWrapDiv,
	FooterTermsDiv,
	TermButton,
	ExtrnalLink,
} from "./FooterStyle";
import nflLogo from "assets/img/mini-logo.png";
import {useI18n} from "modules/hooks";
import styled from "styled-components";
import hubLogo from "assets/img/genius.svg";
import {Exist} from "components/Exist";
import {useSelector} from "react-redux";
import {isMXSelector} from "modules/selectors";
import {PropsWithChildren} from "react";

const FunHubLogo = styled.img`
	margin-top: 30px;
	opacity: 0.5;
`;

interface IProps {
	SponsorLogo?: React.FC;
	hideNflLogo?: boolean;
	sponsorLink?: string;
}

const LinkWrapper: React.FC<PropsWithChildren<{link?: string}>> = ({link, children}) => {
	if (link) {
		return (
			<a href={link} target="_blank" rel="noopener noreferrer">
				{children}
			</a>
		);
	}

	return <>{children}</>;
};

export const Footer: React.FC<IProps> = ({SponsorLogo, hideNflLogo = false, sponsorLink}) => {
	const footerTermsText = useI18n("global_footer_terms");
	const footerDescText = useI18n("global_footer_desc");
	const footerCookiesText = useI18n("global_footer_desc_cookies");
	const footerPrivacyText = useI18n("global_footer_desc_privacy");
	const footerAdChoicesText = useI18n("global_footer_desc_adchoices");
	const isMX = useSelector(isMXSelector);

	return (
		<React.Fragment>
			<FooterWrapper isMX={isMX}>
				<FooterMenuWrapper>
					<FooterImgWrapDiv>
						<Exist when={!hideNflLogo}>
							<FooterImgDiv>
								<FooterImg src={nflLogo} alt={"NFL logo"} />
							</FooterImgDiv>
						</Exist>

						{SponsorLogo ? (
							<FooterImgDiv>
								{" "}
								<LinkWrapper link={sponsorLink}>
									<SponsorLogo />{" "}
								</LinkWrapper>
							</FooterImgDiv>
						) : null}
					</FooterImgWrapDiv>

					<FooterTermsDiv>
						<TermLink to="/help/rules">{footerTermsText}</TermLink>
						<ExtrnalLink href="https://www.nfl.com/legal/privacy/" target="_blank" rel="noopener">
							{footerPrivacyText}
						</ExtrnalLink>
						<ExtrnalLink
							href="https://www.nfl.com/legal/privacy/#InterestBasedAdvertising"
							target="_blank"
							rel="noopener"
						>
							{footerAdChoicesText}
						</ExtrnalLink>
						<Exist when={!!import.meta.env.VITE_ONE_TRUST_ID}>
							<TermButton id="ot-sdk-btn" className="ot-sdk-show-settings">
								{footerCookiesText}
							</TermButton>
						</Exist>
					</FooterTermsDiv>

					<Copywriting>{footerDescText}</Copywriting>
				</FooterMenuWrapper>
				<a href={"https://fanhubmedia.com/"} target={"_blank"} rel={"noreferrer"}>
					<FunHubLogo src={hubLogo} alt={"Fanhub logo"} />
				</a>
			</FooterWrapper>
		</React.Fragment>
	);
};
