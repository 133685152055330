import React, {useRef} from "react";
import styled, {css, keyframes} from "styled-components";
import {IBroadcast} from "modules/types";

const showAnimation1 = keyframes`
	0%, 10% {
		margin-left: 0;
	}
	0%, 60% {
		margin-left: 0;
	}
	65% {
		margin-left: -100%;
	}
	65%, 100% {
		margin-left: -100%;
	}
`;

const animationStyles = css`
	animation: ${showAnimation1} 10s infinite linear alternate;
`;

const Wrapper = styled.div<{withAnimation: boolean}>`
	width: 40%;
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 100%;
	a {
		width: 100%;
		flex: 0 0 auto;
		display: flex;
		justify-content: flex-end;
		position: relative;
		height: 100%;
		&:first-child {
			${(props) => props.withAnimation && animationStyles};
		}
	}
	img {
		max-width: 100%;
		max-height: 100%;
	}
`;

interface IProps {
	broadcasters: Record<string, IBroadcast>;
}

const getBroadcastImage = (broadcast?: string) => {
	if (!broadcast) {
		return "";
	}
	return `${String(import.meta.env.VITE_BROADCASTERS)}${broadcast.replace(" ", "")}.png`;
};

export const Broadcasters: React.FC<IProps> = ({broadcasters}) => {
	const syndicateCountry: string = import.meta.env.VITE_SYNDICATE_COUNTRY || "";
	const broadcast = broadcasters[syndicateCountry.toLowerCase()];
	const broadRef1 = useRef<HTMLAnchorElement>(null);
	const broadRef2 = useRef<HTMLAnchorElement>(null);
	if (!broadcast) {
		return null;
	}
	const broadcast1: Partial<IBroadcast> = {
		broadcast: broadcast.broadcast,
		link: broadcast.link,
	};
	const broadcast2 = {
		broadcast: broadcast.broadcast2,
		link: broadcast.link2,
	};
	return (
		<Wrapper withAnimation={Boolean(broadcast.broadcast2)}>
			<a ref={broadRef1} target={"_blank"} rel={"noreferrer"} href={broadcast1.link}>
				<img src={getBroadcastImage(broadcast1.broadcast)} alt="" />
			</a>
			<a ref={broadRef2} target={"_blank"} rel={"noreferrer"} href={broadcast2.link}>
				<img src={getBroadcastImage(broadcast2.broadcast)} alt="" />
			</a>
		</Wrapper>
	);
};
