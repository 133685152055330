import React from "react";
import styled from "styled-components";
import theme from "assets/css/theme";
import twitter from "assets/img/icons/Twitter.svg";
import facebook from "assets/img/icons/Facebook.svg";
import share from "assets/img/icons/Link.svg";
import {partial} from "lodash";
import {ShareType} from "modules/constants/enums";

const Title = styled.div`
	font-size: 20px;
	color: #333;
	line-height: 24px;
	font-family: ${theme.base_bold_font};
	margin-bottom: 30px;

	@media screen and (max-width: 840px) {
		font-size: 16px;
		line-height: 24px;
	}
`;

const ShareWrapper = styled.div`
	display: flex;
`;

const Link = styled.div`
	width: 42px;
	height: 42px;
	background: url("${share}") center no-repeat;
	cursor: pointer;
`;

const TwitterLink = styled(Link)`
	background-image: url("${twitter}");
	margin: 0 10px;
`;

const FaceBookLink = styled(Link)`
	background-image: url("${facebook}");
`;

const Notify = styled.p`
	align-self: center;
	margin-left: 10px;
	color: ${theme.primary};
`;

interface IProps {
	title: string | undefined;
	onShare: (a: ShareType, b: string) => void;
	onCopy: () => void;
	copied_link: boolean;
	comp_code: string;
}

export const ShareLeague = ({title, onShare, onCopy, copied_link, comp_code}: IProps) => (
	<React.Fragment>
		<Title>{title}</Title>
		<ShareWrapper>
			<FaceBookLink onClick={partial(onShare, ShareType.FB, comp_code)} />
			<TwitterLink onClick={partial(onShare, ShareType.TW, comp_code)} />
			<Link onClick={onCopy} />
			{copied_link ? <Notify>{window.getTranslations("leagueinvite_form_button_copied")}</Notify> : null}
		</ShareWrapper>
	</React.Fragment>
);
