import React, {useCallback, useEffect, useRef} from "react";
import styled from "styled-components";
import {Container} from "components/Container";
import {useDispatch, useSelector} from "react-redux";
import {
	getFirstScheduledBracketIDSelector,
	getFlatRounds,
	getSelectedBracketIDSelector,
} from "modules/selectors/brackets";
import {Select} from "components/Form";
import {OddsType} from "modules/constants/enums";
import {getOddsType, isOddsVisible} from "modules/selectors";
import {switchOddsType} from "modules/actions";
import {Betting888Logo} from "components/SkyBetLogo";
import {BracketItem} from "components/BracketsPicker/BracketItem";
import {media} from "assets/css/media";
import {moveToBracket} from "modules/utils/helpers";
import {setSelectedBracketIDAction} from "modules/actions/brackets";
import fandueLogo from "apps/CA/assets/img/sponsor-fandue-color.png";
import Slider, {Settings} from "react-slick";
import arrowRight from "assets/img/icons/chevron-right.svg";
import {SYNDICATE_COUNTRY, SPONSOR_ENABLED} from "modules/constants";
import {Exist} from "components/Exist";

const Wrapper = styled.div`
	background: #eee;
	padding: 20px 0 40px;

	@media (max-width: ${media.mobile}) {
		padding: 0;
	}

	.slick-slider {
		position: relative;
		margin-left: 15px;
		@media (max-width: ${media.mobile}) {
			margin-left: 0;
		}

		.slick-list {
			overflow: unset;
			z-index: 3;
		}
		.slick-track {
			display: flex;
		}
		.slick-arrow {
			width: 34px;
			height: 34px;
			background: #fff;
			box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.2);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			position: absolute;
			z-index: 4;
			top: 58%;
			&:before {
				content: "";
				display: block;
				background: url("${arrowRight}") no-repeat center center #ffffff;
				background-size: 100%;
				width: 9px;
				height: 14px;
				margin: 0 auto;
			}
			&.slick-prev {
				left: 0;
				@media (max-width: ${media.mobile}) {
					left: 0;
				}
				&:before {
					transform: rotate(180deg);
				}
			}
			&.slick-next {
				right: 15px;
				@media (max-width: ${media.mobile}) {
					left: 90%;
				}
			}
		}
	}
`;
const OddsControlsWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
	@media (max-width: ${media.mobile}) {
		padding: 0 10px;
	}
`;
const OddsControlsWrapperInner = styled.div`
	box-shadow: -28px 13px 15px 2px rgb(238 238 238);
	background: #eeeeee;
	position: relative;
	z-index: 2;
	display: flex;
`;

const OddsTypeSelect = styled(Select)`
	width: 180px;
	margin: 0;
`;

const OddsSponsor = styled.div`
	display: flex;
	flex-flow: column;
	margin-right: 8px;

	p {
		font-size: 12px;
		color: #909090;
		margin-bottom: 5px;
	}
`;

const CaLogo = styled.img`
	width: 100px;
	margin-left: -8px;
`;

const SliderWrapper = styled.div`
	padding-top: 10px;
`;

export const BracketsPicker: React.FC = () => {
	const dispatch = useDispatch();
	const rounds = useSelector(getFlatRounds);
	const isOddsAvailable = useSelector(isOddsVisible);
	const oddsType = useSelector(getOddsType);
	const firstScheduledBracketID = useSelector(getFirstScheduledBracketIDSelector);
	const selectedBracketID = useSelector(getSelectedBracketIDSelector);

	const refSlider = useRef<Slider>(null);

	useEffect(() => {
		if (firstScheduledBracketID) {
			dispatch(setSelectedBracketIDAction(firstScheduledBracketID));
		}
	}, [firstScheduledBracketID, dispatch]);

	useEffect(() => {
		moveToBracket(selectedBracketID);
		if (refSlider) {
			refSlider.current?.slickGoTo(selectedBracketID - 1);
		}
	}, [selectedBracketID]);

	const onSwitchOddsType = useCallback(
		(event: React.SyntheticEvent<HTMLSelectElement>) => {
			dispatch(switchOddsType(event.currentTarget.value as unknown as OddsType));
		},
		[dispatch]
	);

	const settings: Settings = {
		infinite: false,
		speed: 500,
		slidesToShow: 6,
		slidesToScroll: 6,
		initialSlide: 10,
		className: "center",
		centerPadding: "60px",
		responsive: [
			{
				breakpoint: 1800,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
				},
			},
			{
				breakpoint: 1700,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5,
				},
			},
			{
				breakpoint: 1500,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 820,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<Wrapper>
			{isOddsAvailable ? (
				<Container>
					<OddsControlsWrapper>
						<OddsControlsWrapperInner>
							<Exist when={SPONSOR_ENABLED}>
								<OddsSponsor>
									<p>odds by:</p>
									{SYNDICATE_COUNTRY === "CA" ? (
										<CaLogo src={fandueLogo} alt={"Logo"} />
									) : (
										<Betting888Logo />
									)}
								</OddsSponsor>
							</Exist>
							<OddsTypeSelect onChange={onSwitchOddsType} value={oddsType}>
								<option value={OddsType.HeadToHead}>Head to head odds</option>
								<option value={OddsType.SuperBowl}>Super Bowl odds</option>
							</OddsTypeSelect>
						</OddsControlsWrapperInner>
					</OddsControlsWrapper>
				</Container>
			) : null}
			<SliderWrapper>
				{/**
				 An open issue on github https://github.com/akiran/react-slick/issues/2404
				 **/}
				{/* @ts-ignore */}
				<Slider {...settings} ref={refSlider}>
					{rounds.map(({brackets}) =>
						brackets.map((bracket) => <BracketItem key={bracket.bracketID} bracket={bracket} />)
					)}
				</Slider>
			</SliderWrapper>
		</Wrapper>
	);
};
