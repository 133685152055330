import React from "react";
import styled from "styled-components";
import {Container} from "components/Container";
import {IMenuItem} from "modules/types";
import {find, get, intersection} from "lodash";
import {NavLink, useLocation} from "react-router-dom";
import theme from "assets/css/theme";
import {media} from "assets/css/media";

const Wrapper = styled.div`
	width: 100%;
	background: #ffffff;
	height: 48px;
	@media (max-width: ${media.mobile}) {
		display: none;
	}
`;
const WrapperInner = styled(Container)`
	justify-content: flex-start;
`;
const Item = styled(NavLink)`
	font-family: ${theme.menu_font};
	color: #555555;
	font-size: 14px;
	display: flex;
	align-items: center;
	margin: 0 15px;
	text-transform: uppercase;
	&.active {
		color: #064be1;
		border-bottom: 2px solid #064be1;
	}
	&:hover {
		color: #064be1;
	}
`;

interface IProps {
	menuItems: IMenuItem[];
}

export const LeaguesDesktopMenu: React.FC<IProps> = ({menuItems}) => {
	const {pathname} = useLocation();
	const leagueID = pathname.split("/").filter((item) => parseFloat(item))[0];
	const leagueItems = find(menuItems, {label: "global_button_leagues_bracket_button_leagues"});
	const subNav: IMenuItem[] = get(leagueItems, "subItems", []);
	const isShowLeaguesMenu = intersection(pathname.split("/"), ["leagues"]).length;
	const isShowLeagueMenu = intersection(pathname.split("/"), ["league"]).length;
	const leaguesNav = subNav.map((item: IMenuItem) => (
		<Item key={item.label} to={item.link}>
			{window.getTranslations(item.label)}
		</Item>
	));
	return isShowLeaguesMenu || isShowLeagueMenu ? (
		<Wrapper>
			<WrapperInner>
				{isShowLeaguesMenu ? leaguesNav : null}
				{isShowLeagueMenu ? (
					<React.Fragment>
						<Item to={`/league/${leagueID}/ladder`}>
							{window.getTranslations("leagueladder_subnav_th")}
						</Item>
						<Item to={`/league/${leagueID}/about`}>{window.getTranslations("aboutleague_subnav_th")}</Item>
					</React.Fragment>
				) : null}
			</WrapperInner>
		</Wrapper>
	) : null;
};
