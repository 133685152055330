import styled, {css} from "styled-components";
import {ExternalLink} from "components/Link";
import React from "react";
import {Exist} from "components/Exist";
import {SYNDICATE_COUNTRY} from "modules/constants";

interface IOddsItemProps {
	isSelected?: boolean;
}

const getSelectedOddsStyles = ({isSelected}: IOddsItemProps) =>
	isSelected
		? css`
				background: ${SYNDICATE_COUNTRY === "CA" ? "#0a7aff" : "#fc6200"};
				color: #fff;
			`
		: css`
				background: #fff;
				color: #151515;
			`;

const OddsItem = styled(ExternalLink)<IOddsItemProps>`
	min-width: 40px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #fc6200;
	font-size: 10px;
	border-radius: 4px;
	${getSelectedOddsStyles};
`;

const OddsItemNotLink = styled.div<IOddsItemProps>`
	min-width: 40px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #0a7aff;
	font-size: 10px;
	border-radius: 4px;
	${getSelectedOddsStyles};
`;

interface IOddsProps extends IOddsItemProps {
	oddsValue: number;
	oddsID: number;
}

export const Odds: React.FC<IOddsProps> = ({isSelected, oddsValue, oddsID, ...rest}) => (
	<Exist when={Boolean(oddsValue)}>
		<Exist when={SYNDICATE_COUNTRY === "CA"}>
			<OddsItemNotLink isSelected={isSelected} {...rest}>
				{oddsValue}
			</OddsItemNotLink>
		</Exist>

		<Exist when={SYNDICATE_COUNTRY !== "CA"}>
			<OddsItem
				isSelected={isSelected}
				href={`https://www.888sport.com/deeplink/?selections=[${oddsID}]`}
				{...rest}
			>
				{oddsValue}
			</OddsItem>
		</Exist>
	</Exist>
);
