import styled, {keyframes, css} from "styled-components";
import {NavLink} from "react-router-dom";
import theme from "assets/css/theme";

const menuLoading = keyframes`
	from {
        background-size: 25% 2px;
	}

    to {
        background-size: 100% 2px;
    }
`;

const menuItemDesktopStyles = css<{$isHideItem?: boolean}>`
	color: #ffffff;
	font-size: 14px;
	font-family: ${theme.base_bold_font};
	display: ${(props) => (props.$isHideItem ? "none" : "flex")};
	align-items: center;
	justify-content: center;
	margin: 0 10px;
	text-transform: uppercase;
	border: none;
	box-shadow: none;
	background: none;
	cursor: pointer;
	line-height: 1;
	padding: 5px 0;

	&:hover,
	&:active {
		opacity: 1;
	}

	img {
		margin-left: 10px;
	}

	&:disabled {
		opacity: 0.7;
		cursor: not-allowed;
		background: linear-gradient(#fff, #fff);
		background-size: 100% 1px;
		background-repeat: no-repeat;
		background-position: center bottom;
		animation: ${menuLoading} 1s ease infinite;
	}
`;
export const MenuItemDesktop = styled(NavLink).withConfig({
	shouldForwardProp: (prop) => !["$isHideItem"].includes(prop),
})<{$isHideItem?: boolean}>`
	${menuItemDesktopStyles}
`;

export const MenuItemDesktopExternal = styled.a<{$isHideItem?: boolean}>`
	${menuItemDesktopStyles}
`;

export const MenuItemDesktopButton = styled.button<{$isHideItem?: boolean}>`
	${menuItemDesktopStyles}
`;
