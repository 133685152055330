import {EnumRoundStatusType} from "modules/constants/enums";
import {TConference} from "modules/types/brackets";

type TStats =
	| "AVG_DEF_PAL"
	| "AVG_REO_YRD"
	| "AVG_RUO_YRD"
	| "TOT_DEF_FUM"
	| "TOT_DEF_INT"
	| "TOT_DEF_SACK"
	| "TOT_DEF_SAFE"
	| "TOT_DEF_TD"
	| "TOT_KST_EPR"
	| "TOT_KST_FG49"
	| "TOT_KST_FG50"
	| "TOT_KST_PAT"
	| "TOT_KST_PKF"
	| "TOT_REO_FUM"
	| "TOT_REO_TDN"
	| "TOT_REO_TPC"
	| "TOT_RUO_FUM"
	| "TOT_RUO_TDN"
	| "TOT_RUO_TPC";

type TScores = "def_points" | "kst_points" | "reo_points" | "ruo_points";

export interface IFanPrediction {
	count: number;
	percent: number;
}

interface ISquadStats {
	avg_def_points: number;
	avg_game_points: number;
	avg_kst_points: number;
	avg_reo_points: number;
	avg_ruo_points: number;
	fan_predictions: Record<string, IFanPrediction>;
	games_played: number;
	scores: Record<TScores, number>;
	stats: Record<TStats, number>;
	total_def_points: number;
	total_game_points: number;
	total_kst_points: number;
	total_reo_points: number;
	total_ruo_points: number;
}

export interface ISquad {
	id: string;
	seed: number;
	short_name: string;
	full_name: string;
	active: number;
	conference: NonNullable<TConference>;
	locked: number;
	stats: ISquadStats;
	video: string[];
	superbowl_odds_value: number;
	superbowl_odds_id: number;
	away_squad_odds: {
		888: IOddValue;
		fanduel: IOddValue;
	};
	home_squad_odds: {
		888: IOddValue;
		fanduel: IOddValue;
	};
}

export interface IBroadcast {
	broadcast: string;
	link: string;
	broadcast2: string;
	link2: string;
}

export enum MatchStatusEnum {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export interface IOddValue {
	id: string | number;
	value: string | number;
}

export interface IMatch {
	readonly away_game_points: number;
	readonly home_game_points: number;
	readonly away_squad_conference: NonNullable<TConference>;
	readonly home_squad_odds_value: number;
	readonly away_squad_odds_value: number;
	readonly away_squad_odds: {
		888: IOddValue;
		fanduel: IOddValue;
	};
	readonly home_squad_odds: {
		888: IOddValue;
		fanduel: IOddValue;
	};
	readonly bracket_id: number;
	readonly completed: string;
	readonly home_squad_conference: NonNullable<TConference>;
	readonly is_postponed: number;
	readonly id: number;
	readonly round: number;
	readonly match: number;
	readonly home_squad_id: string;
	readonly home_squad_odds_id: number;
	readonly away_squad_id: string;
	readonly away_squad_odds_id: number;
	readonly venue_id: number;
	readonly status: MatchStatusEnum;
	readonly date: string;
	readonly broadcasters: Record<string, IBroadcast>;

	phase: null | unknown;
	video: {
		highlights: string;
	};
	readonly period: string;
	conference: TConference;
}

export interface IRound {
	readonly id: number;
	readonly status: EnumRoundStatusType;
	readonly start: string;
	readonly end: string;
	readonly bye_squads: number[];
	readonly is_bye: number;
	readonly lifted_at: number;
	readonly matches: IMatch[];
	readonly abbr: "DIV" | "WC" | "CON" | "SB" | string;
}

export type IRoundReducer = IRound[];
export type ISquadReducer = ISquad[];

export interface IPropsForFanSquads {
	originRoundID: number;
}

export type TResultPerRoundFansPredictions = IFanPrediction &
	Pick<ISquad, "id" | "full_name" | "active"> &
	IPropsForFanSquads;

export interface IFetchTeamStatistics {
	home_id: string;
	away_id: string;
}

export interface ILeagueRanksStats {
	offense_point_per_game_rank: number;
	offense_yards_per_game_rank: number;
	offense_passing_yards_per_game_rank: number;
	offense_rushing_yards_per_game_rank: number;
	defense_point_per_game_rank: number;
	defense_yards_per_game_rank: number;
	defense_passing_yards_per_game_rank: number;
	defense_rushing_yards_per_game_rank: number;
}

export interface IWdl {
	wdl: "win" | "loss";
	count: number;
}

export interface ITeamStatistics {
	home_squad_id: string;
	away_squad_id: string;
	league_ranks: {[p: number]: ILeagueRanksStats};
	season_record: {[p: string]: IWdl[]};
}

export type LiveScoreSection = "brackets";

export interface ISubscribeToChecksums {
	section: LiveScoreSection;
}
