import {WILD_CARD_ROUND_ID} from "modules/constants";
import {IMatch, IRound, IStore} from "modules/types";
import {find, last, findLast, first, includes, flatten, filter, get, isArray} from "lodash";

import {EnumRoundStatusType} from "modules/constants/enums";
import {createSelector} from "reselect";

export const getRounds = ({rounds = []}: IStore) => (isArray(rounds) ? rounds : []);

export const getRoundsGTEWildcard = ({rounds = []}: IStore) =>
	filter(rounds, (round) => round.id >= WILD_CARD_ROUND_ID);

export const getActualRound = ({rounds = []}: IStore) =>
	find(rounds, ({status}) => [EnumRoundStatusType.ACTIVE, EnumRoundStatusType.SCHEDULED].includes(status)) ||
	last(rounds);

export const getLastRound = ({rounds = []}: IStore) =>
	findLast(rounds, ({status}) => [EnumRoundStatusType.ACTIVE, EnumRoundStatusType.COMPLETE].includes(status)) ||
	first(rounds);

export const getScheduledRound = ({rounds = []}: IStore) =>
	find(rounds, ({status}) => [EnumRoundStatusType.SCHEDULED].includes(status)) || last(rounds);

export const getActualRounds = ({rounds = []}: IStore) => {
	return rounds.filter(
		({status}: IRound) => status === EnumRoundStatusType.SCHEDULED || status === EnumRoundStatusType.ACTIVE
	);
};

export const getScheduledRounds = ({rounds = []}: IStore) => {
	return rounds.filter((round: IRound) => round.status === EnumRoundStatusType.SCHEDULED);
};

export const getLeagueScheduledRounds = ({rounds = []}: IStore) => {
	return rounds.filter(
		(round: IRound) => includes([19, 20], round.id) && round.status === EnumRoundStatusType.SCHEDULED
	);
};
export const getLeagueScheduledRound = createSelector(
	getRounds,
	getLeagueScheduledRounds,
	(rounds: IRound[], scheduled: IRound[]) => (scheduled ? first(scheduled) : last(rounds))
);
export const getRoundById = ({rounds = []}: IStore, gw_id: number) =>
	find(rounds, ({id}) => gw_id === id) || last(rounds);

export const getRoundsMatchesSelector = createSelector(getRounds, (rounds) =>
	flatten(rounds.map((round) => round.matches))
);
export const isGameComplete = createSelector(getRounds, (rounds) => {
	const lastRound = last(rounds);
	const status = get(lastRound, "matches[0].status", "scheduled");
	return status === "complete";
});
export const getRealGameWinner = createSelector(getRoundsMatchesSelector, isGameComplete, (allMatches, isComplete) => {
	const finalMatch: Partial<IMatch> = last(allMatches) || {};
	if (!isComplete) {
		return 0;
	}
	const {away_game_points = 0, home_game_points = 0, home_squad_id, away_squad_id} = finalMatch;
	return home_game_points > away_game_points ? home_squad_id : away_squad_id;
});
