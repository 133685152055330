import React from "react";
import {Modal} from "components/Modal";
import styled from "styled-components";
import logo from "assets/img/logo-tall.svg";
import {useI18n} from "modules/hooks";
import {useLocation} from "react-router-dom";
import deLogo from "assets/img/de-logo-tall.svg";
import {get} from "lodash";
import {IS_OFF_SEASON, IS_PRE_SEASON} from "modules/constants";

const Wrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 35px 10px;
`;
const Logo = styled.img`
	width: 100%;
	max-width: 175px;
	margin-bottom: 20px;
`;
const Text = styled.div`
	font-size: 16px;
	text-align: center;
	line-height: 26px;
	max-width: 500px;
`;
const WrapperOffSeasonModal = styled.div`
	.modal-wrapper {
		z-index: 21 !important;
	}
`;

const SponsorLogo = styled.img`
	margin-bottom: 10px;
	max-width: 150px;
`;

const syndicateCountry: string = import.meta.env.VITE_SYNDICATE_COUNTRY || "";

const getSponsorLogo = () => {
	const sponsorLogos = {
		// US: PZHLogo,
		// MX: betcrisLogos,
	};

	return get(sponsorLogos, syndicateCountry, null);
};

export const OffSeasonModal: React.FC = () => {
	const {pathname} = useLocation();
	const closeClickPlaceholder = () => null;
	const text = useI18n("landing.modal.thanks_for_playing");
	const isOffSeasonPage = pathname === "/";
	const isShowOffSeason = isOffSeasonPage && IS_OFF_SEASON && !IS_PRE_SEASON;
	const headerLogo = import.meta.env.VITE_NAME === "nfl-de" ? deLogo : logo;
	const sponsorLogo = getSponsorLogo();
	return isShowOffSeason ? (
		<WrapperOffSeasonModal>
			<Modal onClose={closeClickPlaceholder} isCloseable={false} width="750px">
				<Wrapper>
					<Logo src={headerLogo} alt={"NFL logo"} />
					{sponsorLogo ? <SponsorLogo src={sponsorLogo} alt={"Sponsor logo"} /> : null}
					<Text>{text}</Text>
				</Wrapper>
			</Modal>
		</WrapperOffSeasonModal>
	) : null;
};
