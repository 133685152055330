import React, {useEffect, PropsWithChildren} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchTranslationsAction} from "modules/actions/translations";
import {getTranslationStore} from "modules/selectors";
import {PagePreloader} from "components/Preloader";
import {keys} from "lodash";

export const Translations: React.FC<PropsWithChildren> = ({children}) => {
	const dispatch = useDispatch();
	const translations = useSelector(getTranslationStore);
	useEffect(() => {
		dispatch(fetchTranslationsAction());
	}, [dispatch]);
	window.translations = translations;
	return keys(translations).length ? <React.Fragment>{children}</React.Fragment> : <PagePreloader />;
};
