import * as _ from "lodash";
import React, {Component, PropsWithChildren} from "react";
import styled, {css} from "styled-components";
import arrow_down from "assets/img/icons/arrow-down.svg";
import {IFunc0, IFunc2} from "modules/types";

const ContentWrapper = styled.div.withConfig({
	shouldForwardProp: (prop) => !["is_open"].includes(prop),
})<IState>`
	height: auto;
	max-height: 0;
	transition: max-height 0.5s ease;
	overflow: hidden;

	${({is_open}) =>
		is_open &&
		css`
			max-height: fit-content;
		`}
`;

const Icon = styled.div.withConfig({
	shouldForwardProp: (prop) => !["customIcon"].includes(prop),
})<ICustomIconProps>`
	position: absolute;
	right: 20px;
	top: 50%;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	background: url("${arrow_down}") center no-repeat;
	background-size: 16px;
	cursor: pointer;
	transition: transform 0.3s ease;
	${({is_open}) =>
		is_open &&
		css`
			transform: rotate(180deg) translateY(50%);
		`}
`;

interface ICustomIcon {
	open_icon: string;
	closed_icon: string;
}

interface ICustomIconProps extends IState {
	customIcon?: ICustomIcon;
}

const CustomIcon = styled(Icon)`
	transform: none;
	top: 15px;
	background-image: url("${({is_open, customIcon}) =>
		customIcon ? (is_open ? customIcon.open_icon : customIcon.closed_icon) : ""}");
`;

const ToogleIconWapper = styled.div.withConfig({
	shouldForwardProp: (prop) => !["scope_on_click"].includes(prop),
})<Partial<IIcon>>`
	position: relative;
	pointer-events: all;
	cursor: ${({scope_on_click}) => (scope_on_click ? "pointer" : "inherit")};
`;

interface IIcon {
	scope_on_click?: boolean;
	state: boolean;
	children: React.ReactNode;
	onClick: IFunc0;
	customIcon?: ICustomIcon;
}

interface IState {
	is_open: boolean;
}

interface IProps {
	children: IFunc2<IFunc0, boolean, never>;
}

export const ToggleIcon = (props: IIcon) => {
	const {children, onClick, state, scope_on_click, customIcon, ...rest} = props;
	const IconComponent = customIcon ? CustomIcon : Icon;

	return (
		<ToogleIconWapper onClick={scope_on_click ? onClick : _.noop} scope_on_click={scope_on_click}>
			{children}

			<IconComponent
				onClick={scope_on_click ? _.noop : onClick}
				is_open={state}
				customIcon={customIcon}
				{...rest}
			/>
		</ToogleIconWapper>
	);
};

export const AccordionContent: React.FC<PropsWithChildren<{state: boolean}>> = ({children, state}) => (
	<ContentWrapper is_open={state}>{children}</ContentWrapper>
);

export class Accordion extends Component<IProps, IState> {
	public state = {
		is_open: false,
	};

	public toggleState() {
		this.setState((state: IState) => ({
			is_open: !state.is_open,
		}));
	}

	// @ts-ignore
	public render() {
		const {children} = this.props;

		return children ? children(this.toggleState.bind(this), this.state.is_open) : null;
	}
}
